import React, {useState} from 'react';
import {Radio} from "semantic-ui-react";

const GreetingSuffix = ({greetingSuffix,setGreetingSuffix,firstNames,lastName,titleText}) => {

  const [greetingSuffixes] = useState([["from "],[ "from the "],[ "from "]])

  const handleChangeGreetingSuffix = (e, {value}) => {
    setGreetingSuffix(value)
  }

  const createGreetingSuffixes = (key) => {
    if(greetingSuffixes[0] === key) {
      return `${key} ${titleText} ${firstNames} ${lastName}`
    } else if(greetingSuffixes[1] === key) {
      return `${key} ${lastName} family.`
    } else if(greetingSuffixes[2] === key) {
      return `${key} ${firstNames} ${lastName}.`
    }
  }

  return (
    <>
      <div className="default-greeting-label">Greeting Suffix</div>
      {greetingSuffixes.map((key, value) => <Radio key={key}
                                                  //  label={greetingSuffixes[0] === key
                                                  //    ?`${key} ${titleText} ${firstNames} ${lastName}`
                                                  //    :`${key} ${lastName} family.`}
                                                  label={createGreetingSuffixes(key)}
                                                   name='radioGroup'
                                                   value={value}
                                                   checked={greetingSuffix === value}
                                                   onChange={(e, value) =>
                                                     handleChangeGreetingSuffix(e, value)}
      />)}
    </>
  );
};

export default GreetingSuffix;