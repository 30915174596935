import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Icon, List, Menu, Segment, Sidebar} from 'semantic-ui-react';
import cn from 'classnames';
import './AppSidebar.css';

const AppSidebar = ({children}) => {
  const [subOrganization, setSubOrganization] = useState(false);
  const [subOrders, setSubOrders] = useState(false);
  const {pathname} = useLocation();

  return (
    <div className='SidebarPushable-Wrapper'>
      <Sidebar.Pushable as={Segment} id='page-content'>
        <Sidebar
          as={Menu}
          animation='uncover'
          icon='labeled'
          inverted
          vertical
          visible={true}
          width='thin'
        >
          <Link to='/search'>
            <Menu.Item active={pathname === '/search'}>
              <Icon name='users'/>
              Customers
            </Menu.Item>
          </Link>
          <Link to='/merge'>
            <Menu.Item active={pathname === '/merge'}>
              <Icon name='add user'/>
              New Users
            </Menu.Item>
          </Link>
          <Menu.Item onClick={() => setSubOrders(!subOrders)}>
            <Icon name='ordered list'/>
            Orders
            <div>
              {subOrders
                ? <Icon name='caret up' size='large'/>
                : <Icon name='caret down' size='large'/>
              }
            </div>
          </Menu.Item>
          <List className={cn(['subList', {hidden: !subOrders}])}>
            <List.Item active={pathname === '/orders'}>
              <Link to='/orders'>
                <List.Icon name='suitcase'/>
                <List.Content>All Orders</List.Content>
              </Link>
            </List.Item>
          </List>
          <List className={cn(['subList', {hidden: !subOrders}])}>
            <List.Item active={pathname === '/invoices'}>
              <Link to='/invoices'>
                <List.Icon name='file alternate'/>
                <List.Content>Invoices</List.Content>
              </Link>
            </List.Item>
          </List>

          <Link to='/pekalach'>
            <Menu.Item active={pathname === '/pekalach'}>
              <Icon name='table'/>
              <div>Pekalach</div>
            </Menu.Item>
          </Link>

          <Menu.Item

            onClick={() => setSubOrganization(!subOrganization)}
          >
            <Icon name='building'/>
            Organization
            <div>
              {subOrganization
                ? <Icon name='caret up' size='large'/>
                : <Icon name='caret down' size='large'/>
              }
            </div>
          </Menu.Item>

          <List className={cn(['subList', {hidden: !subOrganization}])}>
            <List.Item active={pathname === '/setting'}>
              <Link to='/setting'>
                <List.Icon name='setting'/>
                <List.Content>Settings</List.Content>
              </Link>
            </List.Item>
          </List>
          <List className={cn(['subList', {hidden: !subOrganization}])}>
            <List.Item active={pathname === '/cities'}>
              <Link to='/cities'>
                <List.Icon name='map marker alternate'/>
                <List.Content>Cities</List.Content>
              </Link>
            </List.Item>
          </List>
          <List className={cn(['subList', {hidden: !subOrganization}])}>
            <List.Item active={pathname === '/tools'}>
              <Link to='/tools'>
                <List.Icon name='wrench'/>
                <List.Content>Tools</List.Content>
              </Link>
            </List.Item>
          </List>
          <Link to='/report'>
            <Menu.Item active={pathname === '/report'}>
              <Icon name='check circle'/>
              <div>Reports</div>
            </Menu.Item>
          </Link>
          <Link to='/new-email'>
            <Menu.Item active={pathname === '/new-email'}>
              <Icon name='mail'/>
              <div>Email</div>
            </Menu.Item>
          </Link>
        </Sidebar>

        <Sidebar.Pusher id='content'>
          <Segment className='content-box'>
            {children}
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  )
};

export default AppSidebar;
