import React, {useContext, useEffect, useState} from 'react';
import ReactSwitch from 'react-switch';
import {Button, Checkbox, Form, Modal, Radio, TextArea} from 'semantic-ui-react';
import {EditCustomerContext} from '../../contexts/EditCustomerProvider';
import {getCustomerInfo} from '../../services/customerService';
import EZInput from '../EZInput/EZInput';
import PlacesAutocomplete from '../PlacesAutocomplete';
import TitlesCombo from '../TitlesCombo/TitlesCombo';
import './CustomerForm.css';
import error_img from '../../images/status_error.svg';
import {CustomersContext} from '../../contexts/CustomersProvider';
import {NewCustomersContext} from '../../contexts/NewCustomerProvider';
import cn from 'classnames';
import StaffCategoriesCombo from '../StaffCategoriesCombo/StaffCategoriesCombo';
import CustomersFormOrder from "../CustomersFormOrder/CustomersFormOrder";


const Switcheroo = ({flag, handleChange, label = ''}) => (
  <div>
    <label>
      <ReactSwitch
        width={50}
        height={25}
        onChange={handleChange}
        checked={flag}
      />
      <span className="switch-label">{label}</span>
    </label>
  </div>
);

const CustomerEditForm = ({
                            customerInfo,
                            setCustomerFirstNames,
                            setCustomerLastName,
                            setCustomerEmail,
                            setCustomerPhone,
                            setCustomerInternalComment,
                            setCustomerTitleId,
                            setCustomerTitle,
                            setCustomerDefaultGreeting,
                            setCustomerStaff,
                            setCustomerStaffName,
                            setCustomerStaffPosition,
                            setCustomerStaffCategoryIds,
                            setCustomerAddress,
                            setCustomerAddressLine2,
                            setCustomerReciprocity,
                            setCustomerCardOnly,
                            setCustomerEmailOnly,
                            setCustomerBasketWithoutOrders,
                            setCustomerCheckCustomer,
                            setCustomerPaperOrder,
                            setCustomerDisabled,
                            setCustomerAdmin,
                            setCustomerNewCustomer,
                            setCustomerInternal,
                            updateCustomer,
                            isNewCustomer,
                            uuid,
                            setGreetingSuffix
                          }) => {
  const currentContext = (isNewCustomer) ? NewCustomersContext : CustomersContext;
  const {removeCustomer} = useContext(currentContext);
  const {
    setEditInProgress,
    editInProgress,
    saveEditInProgress,
    errors,
    setErrors,
  } = useContext(EditCustomerContext);

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const {
    titleId: acctTitleId,
    firstNames: acctFirstNames,
    lastName: acctLastName,
    fullAddress: acctAddress,
    addressLine2: acctAddressLine2,
    googlePlaceId: acctGooglePlaceId,
    phone: acctPhoneNumber,
    internalComment: acctInternalComment,
    reciprocation: acctReciprocation,
    admin: acctAdmin,
    newCustomer: accNewCustomer,
    staff: acctStaff,
    staffName: acctStaffName,
    staffPosition: acctStaffPosition,
    staffCategoryIds: acctStaffCategoryIds,
    internal: acctInternal,
    cardOnly: acctCardOnly,
    emailOnly: acctEmailOnly,
    paperOrder: acctPaperOrder,
    basketWithoutOrders: acctBasketWithoutOrders,
    checkCustomer: acctCheckCustomer,
    disabled: acctDisabled,
    email: acctEmail,
    defaultGreeting: acctDefaultGreeting,

    title,
    customerId,
  } = customerInfo;
  const [titleId, setTitleId] = useState(acctTitleId || 1);
  const [firstNames, setFirstNames] = useState(acctFirstNames || '');
  const [defaultGreeting, setDefaultGreeting] = useState(
    acctDefaultGreeting || ''
  );
  const [email, setEmail] = useState(acctEmail || '');
  const [lastName, setLastName] = useState(acctLastName || '');
  const [addres, setAddres] = useState(acctAddress || '');
  const [addressLine2, setAddressLine2] = useState(acctAddressLine2 || '');
  const [googlePlaceId, setGooglePlaceId] = useState(acctGooglePlaceId || '');
  const [phoneNumber, setPhoneNumber] = useState(acctPhoneNumber || '');
  const [internalComment, setInternalComment] = useState(acctInternalComment || '');
  const [reciprocityFlag, setReciprocityFlag] = useState(
    Boolean(acctReciprocation)
  );
  const [adminFlag, setAdminFlag] = useState(Boolean(acctAdmin));
  const [newCustomerFlag, setNewCustomerFlag] = useState(Boolean(accNewCustomer));
  const [disabledFlag, setDisabledFlag] = useState(Boolean(acctDisabled));
  const [staffFlag, setStaffFlag] = useState(Boolean(acctStaff));
  const [staffName, setStaffName] = useState(acctStaffName || '');
  const [staffPosition, setStaffPosition] = useState(acctStaffPosition || '');
  const [staffCategoryIds, setStaffCategoryIds] = useState(
    acctStaffCategoryIds || []
  );
  const [internalFlag, setInternalFlag] = useState(Boolean(acctInternal));
  const [cardOnlyFlag, setCardOnlyFlag] = useState(Boolean(acctCardOnly));
  const [emailOnlyFlag, setEmailOnlyFlag] = useState(Boolean(acctEmailOnly));
  const [paperOrderFlag, setPaperOrderFlag] = useState(Boolean(acctPaperOrder));
  const [basketWithoutOrdersFlag, setBasketWithoutOrdersFlag] = useState(
    Boolean(acctBasketWithoutOrders)
  );
  const [checkCustomerFlag, setCheckCustomerFlag] = useState(
    Boolean(acctCheckCustomer)
  );
  const [getGreetingSuffix, setGetGreetingSuffix] = useState(customerInfo.greetingSuffix)
  const [greetingSuffixes] = useState(customerInfo.greetingSuffixes)

  const handleChangeGreetingSuffix = (e, {value}) => {
    setGetGreetingSuffix(value)
    setGreetingSuffix(value)
    setEditInProgress(true)
  }

  const handleChangeFirstNames = (e) => {
    setFirstNames(e.target.value);
    setCustomerFirstNames(e.target.value);

    const {firstNames, ...otherErrors} = errors;
    if (e.target.value.trim().length === 0) {
      setErrors({...otherErrors, firstNames: 'Required Field'});
    } else {
      setErrors({...otherErrors});
    }
    setEditInProgress(true);
  };

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
    setCustomerLastName(e.target.value);

    const {lastName, ...otherErrors} = errors;
    if (e.target.value.trim().length === 0) {
      setErrors({...otherErrors, lastName: 'Required Field'});
    } else {
      setErrors({...otherErrors});
    }
    setEditInProgress(true);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setCustomerEmail(e.target.value);
    setEditInProgress(true);
  };
  const handleChangeStaffName = (e) => {
    if (staffFlag) {
      setStaffName(e.target.value);
      setCustomerStaffName(e.target.value);
      setEditInProgress(true);
    }
  };
  const handleChangeStaffPosition = (e) => {
    if (staffFlag) {
      setStaffPosition(e.target.value);
      setCustomerStaffPosition(e.target.value);
      setEditInProgress(true);
    }
  };

  const handleChangeDefaultGreeting = (e) => {
    setDefaultGreeting(e.target.value);
    setCustomerDefaultGreeting(e.target.value);
    setEditInProgress(true);

    const {defaultGreeting, ...otherErrors} = errors;
    if (e.target.value.trim().length === 0) {
      setErrors({...otherErrors, defaultGreeting: 'Required Field'});
    } else {
      setErrors({...otherErrors});
    }
  };

  const handleChangeTitleId = ({titleId, title}) => {
    setTitleId(titleId);
    setCustomerTitleId(titleId);
    setCustomerTitle(title);
    setEditInProgress(true);
  };

  const handleChangeStaffCategories = (categories) => {
    const newCategoryIds = categories.map(({value}) => value);
    setStaffCategoryIds(newCategoryIds);
    setCustomerStaffCategoryIds(newCategoryIds);
    setEditInProgress(true);
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
    setCustomerPhone(e.target.value);
    setEditInProgress(true);
  };

  const handleChangeInternalComment = (e) => {
    setInternalComment(e.target.value);
    setCustomerInternalComment(e.target.value);
    setEditInProgress(true);
  };

  const handleChangeAddressLine2 = (e) => {
    setAddressLine2(e.target.value);
    setCustomerAddressLine2(e.target.value);
    setEditInProgress(true);
  };

  const handleChangeReciprocity = () => {
    setCustomerReciprocity(!reciprocityFlag);
    setReciprocityFlag(!reciprocityFlag);
    setEditInProgress(true);
  };

  const handleChangeCardOnly = () => {
    setCustomerCardOnly(!cardOnlyFlag);
    setCardOnlyFlag(!cardOnlyFlag);
    setEditInProgress(true);
  };

  const handleChangeEmailOnly = () => {
    setCustomerEmailOnly(!emailOnlyFlag);
    setEmailOnlyFlag(!emailOnlyFlag);
    setEditInProgress(true);
  };

  const handleChangeBasketWithoutOrders = () => {
    setCustomerBasketWithoutOrders(!basketWithoutOrdersFlag);
    setBasketWithoutOrdersFlag(!basketWithoutOrdersFlag);
    setEditInProgress(true);
  };
  const handleCheckCustomer = () => {
    setCustomerCheckCustomer(!checkCustomerFlag);
    setCheckCustomerFlag(!checkCustomerFlag);
    setEditInProgress(true);
  };
  const handleChangePaperOrder = () => {
    setCustomerPaperOrder(!paperOrderFlag);
    setPaperOrderFlag(!paperOrderFlag);
    setEditInProgress(true);
  };

  const handleChangeDisabled = () => {
    setCustomerDisabled(!disabledFlag);
    setDisabledFlag(!disabledFlag);
    setEditInProgress(true);
  };
  const handleChangeAdmin = () => {
    setCustomerAdmin(!adminFlag);
    setAdminFlag(!adminFlag);
    setEditInProgress(true);
  };
  const handleChangeNewCustomer = () => {
    setCustomerNewCustomer(!newCustomerFlag);
    setNewCustomerFlag(!newCustomerFlag);
    setEditInProgress(true);
  };
  const handleChangeStaff = (checked) => {
    setEditInProgress(true);
    setStaffFlag(checked);
    setCustomerStaff(checked);

    if (!checked) {
      setStaffName('');
      setCustomerStaffName('');
      setStaffPosition('');
      setCustomerStaffPosition('');
      setStaffCategoryIds([]);
      setCustomerStaffCategoryIds([]);
    }
  };
  const handleChangeInternal = () => {
    setCustomerInternal(!internalFlag);
    setInternalFlag(!internalFlag);
    setEditInProgress(true);
  };
  const handleChangeAddress = ({address = '', googlePlaceId}) => {
    setAddres(address);
    setCustomerAddress(address);
    setEditInProgress(true);

    const {addres, ...otherErrors} = errors;
    if (address.trim().length === 0) {
      setErrors({...otherErrors, addres: 'Required Field'});
    } else {
      setErrors({...otherErrors});
    }
  };

  const fullName = `${lastName}, ${title} ${firstNames}`;

  console.log({staffCategoryIds});

  return (
    <div className="review-info-content-boxx">
      <Modal
        size="mini"
        dimmer={'blurring'}
        open={openConfirmDeleteModal}
        onClose={() => setOpenConfirmDeleteModal(false)}
      >
        <Modal.Header>Delete Customer Confirmation</Modal.Header>
        <Modal.Content>
          Are you sure you want to delete <strong>{fullName}</strong>?
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              setOpenConfirmDeleteModal(false);
            }}
          >
            Keep
          </Button>
          <Button
            loading={deleteInProgress}
            negative
            onClick={() => {
              setDeleteInProgress(true);
              setTimeout(async () => {
                await removeCustomer(customerId);
                setOpenConfirmDeleteModal(false);
                setEditInProgress(false);
                setDeleteInProgress(false);
              }, 300);
            }}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
        }}
      >
        <div className="two-column-container">
          <div className="heading">Contact Information</div>
        </div>

        <div className="two-column-container">
          <EZInput
            optional
            className="email-field"
            label="Email"
            labelClassName="code"
            name="email"
            value={email}
            handleValueChange={handleChangeEmail}
          />

          <TitlesCombo
            handleTitleIdChange={handleChangeTitleId}
            initialTitleId={titleId}
          />
        </div>
        <div className="two-column-container">
          <EZInput
            errors={errors}
            className="first-names-field"
            label="First Name(s)"
            labelClassName="code"
            name="firstNames"
            value={firstNames}
            handleValueChange={handleChangeFirstNames}
          />

          <EZInput
            errors={errors}
            className="last-name-field"
            label="Last Name"
            labelClassName="code"
            name="lastName"
            value={lastName}
            handleValueChange={handleChangeLastName}
          />
        </div>
        <div className="two-column-container">
          <PlacesAutocomplete
            errors={errors}
            initialAddress={addres}
            onAddressChange={handleChangeAddress}
          />
        </div>
        <div className="two-column-container">
          <EZInput
            className="address-line2-field"
            optional
            label="Apartment or Suite"
            labelClassName="code"
            name="addressLine2"
            value={addressLine2}
            handleValueChange={handleChangeAddressLine2}
            inputProps={{placeholder: 'Apartment/Suite'}}
          />
          <EZInput
            className="phone-number-field"
            label="Phone Number"
            labelClassName="code"
            name="phoneNumber"
            value={phoneNumber}
            optional
            handleValueChange={handleChangePhoneNumber}
          />
        </div>
        <div className="two-column-container">
          <hr className="section-separator"/>
        </div>
        <CustomersFormOrder uuid={uuid} customerId={customerId}/>
        <div className="two-column-container">
          <hr className="section-separator"/>
        </div>
        <div className="two-column-container-checkboxes">
          <div>
            <div className="heading">Order Settings</div>
            <div className="checkboxes-container">
              <Checkbox
                className="ez-checkbox"
                label="Reciprocity"
                onClick={handleChangeReciprocity}
                checked={reciprocityFlag}
              />
              <Checkbox
                className="ez-checkbox"
                label="Card Only"
                checked={cardOnlyFlag}
                onClick={handleChangeCardOnly}
              />
              <Checkbox
                className="ez-checkbox"
                label="Email Only"
                checked={emailOnlyFlag}
                onClick={handleChangeEmailOnly}
              />
              <Checkbox
                className="ez-checkbox"
                label="Basket Without Orders"
                checked={basketWithoutOrdersFlag}
                onClick={handleChangeBasketWithoutOrders}
              />
              <Checkbox
                className="ez-checkbox"
                label="Paper Order"
                checked={paperOrderFlag}
                onClick={handleChangePaperOrder}
              />
              <Checkbox
                className="ez-checkbox"
                label="Pay By Check"
                checked={checkCustomerFlag}
                onClick={handleCheckCustomer}
              />
            </div>
          </div>
          <div>
            <div className="heading">Account Settings</div>
            <div className="checkboxes-container">
              <Checkbox
                className="ez-checkbox"
                label="Disabled"
                checked={disabledFlag}
                onClick={handleChangeDisabled}
              />
              <Checkbox
                className="ez-checkbox"
                label="Internal"
                checked={internalFlag}
                onClick={handleChangeInternal}
              />
              <Checkbox
                className="ez-checkbox"
                label="Admin"
                checked={adminFlag}
                onClick={handleChangeAdmin}
              />
              <Checkbox
                className="ez-checkbox"
                label="New Customer"
                checked={newCustomerFlag}
                onClick={handleChangeNewCustomer}
              />
            </div>
          </div>
        </div>

        <div className="two-column-container default-greeting-container">
          <div className="default-greeting-label">Default Greeting</div>
          <TextArea
            placeholder={`A freilechen Purim`}
            className={cn('default-greeting-text', {
              error: errors['defaultGreeting'],
            })}
            value={defaultGreeting}
            onChange={handleChangeDefaultGreeting}
          />
          <div className="default-greeting-label">Greeting Suffix</div>
          {greetingSuffixes.map((key,value) => <Radio key={key}
                                                      label={key}
                                                      name='radioGroup'
                                                      value={value}
                                                      checked={getGreetingSuffix === value}
                                                      onChange={(e,value)=>handleChangeGreetingSuffix(e, value)}
          />)}
          {errors['defaultGreeting'] && (
            <div className="error-message-div" style={{marginTop: '-10px'}}>
              <img src={error_img} alt="error-message-icon" height="20px"/>
              <span className="error-message">{errors['defaultGreeting']}</span>
            </div>
          )}
        </div>
        <div className="two-column-container default-greeting-container">
          <div className="default-greeting-label">Internal Comment</div>
          <TextArea
            className={cn('default-greeting-text', {
            })}
            value={internalComment}
            onChange={handleChangeInternalComment}
          />
        </div>
        <div className="two-column-container">
          <hr className="section-separator"/>
        </div>

        <div className="two-column-container staff-container">
          <div className="heading">Staff</div>
          <Switcheroo flag={staffFlag} handleChange={handleChangeStaff}/>
        </div>

        {staffFlag && (
          <>
            <div className="two-column-container">
              <EZInput
                optional
                className="staff-name-field"
                label="Staff Name"
                labelClassName="code"
                name="staffName"
                value={staffName}
                handleValueChange={handleChangeStaffName}
              />
              <EZInput
                optional
                className="staff-position-field"
                label="Staff Position"
                labelClassName="code"
                name="staffPosition"
                value={staffPosition}
                handleValueChange={handleChangeStaffPosition}
              />
            </div>
            <div className="two-column-container">
              <StaffCategoriesCombo
                initialStaffCategoryIds={staffCategoryIds}
                handleChangeStaffCategories={handleChangeStaffCategories}
              />
            </div>
          </>
        )}

        <div className="two-column-container">
          <hr className="section-separator"/>
        </div>

        <div className="two-column-container action-btn-container">
          <Button
            basic
            color="red"
            className="ez-basic-btn"
            onClick={() => setOpenConfirmDeleteModal(true)}
          >
            Delete
          </Button>
          {editInProgress && (
            <Button
              primary
              onClick={() => {
                // const errors = validateFields();
                // console.log({ errors });
                // if (Object.keys(errors).length === 0) {
                updateCustomer();
                // } else {
                //   setOpenValidationModal(true);
                // }
              }}
              loading={saveEditInProgress}
            >
              Save
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

const CustomerForm = ({
                        customerId,
                        setCustomerFirstNames,
                        setCustomerLastName,
                        setCustomerEmail,
                        setCustomerPhone,
                        setCustomerInternalComment,
                        setCustomerTitleId,
                        setCustomerTitle,
                        setCustomerDefaultGreeting,
                        setCustomerStaff,
                        setCustomerStaffName,
                        setCustomerStaffPosition,
                        setCustomerStaffCategoryIds,
                        setCustomerAddress,
                        setCustomerAddressLine2,
                        setCustomerReciprocity,
                        setCustomerCardOnly,
                        setCustomerEmailOnly,
                        setCustomerBasketWithoutOrders,
                        setCustomerCheckCustomer,
                        setCustomerPaperOrder,
                        setCustomerDisabled,
                        setCustomerAdmin,
                        setCustomerNewCustomer,
                        setCustomerInternal,
                        updateCustomer,
                        isNewCustomer,
                        setGreetingSuffix,
                        uuid,
                      }) => {

  const [customerInfo, setCustomerInfo] = useState(null);

  useEffect(() => {
    (async (id) => {
      setCustomerInfo(await getCustomerInfo({customerId: id}));
    })(customerId);
  }, []);

  if (!customerInfo) {
    return <div>Loading...</div>;
  }

  return (
    <CustomerEditForm
      customerInfo={customerInfo}
      setCustomerFirstNames={setCustomerFirstNames}
      setCustomerLastName={setCustomerLastName}
      setCustomerEmail={setCustomerEmail}
      setCustomerPhone={setCustomerPhone}
      setCustomerInternalComment={setCustomerInternalComment}
      setCustomerDefaultGreeting={setCustomerDefaultGreeting}
      setCustomerTitleId={setCustomerTitleId}
      setCustomerTitle={setCustomerTitle}
      setCustomerStaff={setCustomerStaff}
      setCustomerStaffName={setCustomerStaffName}
      setCustomerStaffPosition={setCustomerStaffPosition}
      setCustomerStaffCategoryIds={setCustomerStaffCategoryIds}
      setCustomerAddress={setCustomerAddress}
      setCustomerAddressLine2={setCustomerAddressLine2}
      setCustomerReciprocity={setCustomerReciprocity}
      setCustomerCardOnly={setCustomerCardOnly}
      setCustomerEmailOnly={setCustomerEmailOnly}
      setCustomerBasketWithoutOrders={setCustomerBasketWithoutOrders}
      setCustomerCheckCustomer={setCustomerCheckCustomer}
      setCustomerPaperOrder={setCustomerPaperOrder}
      setCustomerDisabled={setCustomerDisabled}
      setCustomerAdmin={setCustomerAdmin}
      setCustomerNewCustomer={setCustomerNewCustomer}
      setCustomerInternal={setCustomerInternal}
      updateCustomer={updateCustomer}
      isNewCustomer={isNewCustomer}
      uuid={uuid}
      setGreetingSuffix={setGreetingSuffix}
    />
  );
};

export default CustomerForm;
