import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {BACKEND_URL, FRONTEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {Button, Input, Modal} from "semantic-ui-react";
import {Editor} from "@tinymce/tinymce-react";
import style from "./newTemplate.module.css"

const NewTemplate = () => {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [openErrorMassage, setOpenErrorMassage] = useState(false);
  const [text, setText] = useState(null);
  const [value, setValue] = useState('');
  const [started, setStarted] = useState({});
  const [name, setName] = useState("");

  useEffect(() => {
    setIsLoading(true)
    fetch(`${BACKEND_URL}api/v1/templates/starter`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValue(result.template.version && result.template.version.template);
        setStarted(result.template);
        setIsLoading(false);
      })

      .catch((error) => console.log('No way! ', error));

  }, []);

  const createTemplate = async (newItem) => {
    setIsLoading(true)
    const response = await fetch(
      `${BACKEND_URL}api/v1/templates`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
        body: JSON.stringify(newItem),
      }
    ).catch((err) => {
      console.error(`Error CreateItem:`, err);
      return {errors: err};
    });
    if (!response.ok) {
      const errors = await response.json();
      setIsLoading(false);
      setError(`Error status:${errors.status}`)
      setOpenErrorMassage(true)
      console.log('ERROR in response (CreateItem): ', errors);
      return {errors};
    }
    setTimeout(() => {
      setIsLoading(false);
      history.push('/new-email');
    }, 2500);
    const data = await response.json();
    console.log('RESULT: ', data);
    return data;
  };
  const templateNameHandler = (e) => {
    if (e.currentTarget.value.includes(" ")) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    }
    setName(e.target.value)
  };
  const templateDescriptionHandler = (e) => {
    setText(e.target.value)
  };

  const handlerSaveTemplate = () => {
    if (!name) {
      setError("Enter name")
    }
    else if (!text) {
      setError("Enter description")
    } else {
      const item = {
        name: name,
        description: text,
        template: value,
      };
      createTemplate(item);
    }
  };

  const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return (
    <div className={style["content"]}>
      <div className={style["header-name"]}><h2> New template </h2></div>

      {isLoading
        ? <h3 style={{margin: "50px", textAlign: "center"}}>Loading...</h3>
        : <>
          <div className={style["label-name"]}>
            Template Name
          </div>
          <div>
            <Input className={style["template-input"]}
                   error={error}
                   size={"large"}
                   onChange={(e) => templateNameHandler(e)}
                   placeholder='Enter Template Name'
                   value={name}
            />
          </div>
          <div>
            <Input className={style["template-input"]}
                   error={error}
                   size={"large"}
                   onChange={(e) => templateDescriptionHandler(e)}
                   placeholder='Enter description'
                   value={text}
            />
          </div>
          <Editor
            apiKey='hec6cb4vrglyd7tgzoocbfnb3z0f8l2ibga05b1g2szqrbem'
            initialValue={started.version && started.version.template}
            value={value}
            // onInit={(evt, editor) => {
              // setValue(value);
              // setText(editor.getContent({format: 'text'}));
            // }}
            onEditorChange={(newValue, editor) => {
              setValue(newValue);
              // setText(editor.getContent({format: 'text'}));
            }}
            init={{
              plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
              imagetools_cors_hosts: ['picsum.photos'],
              menubar: 'file edit view insert format tools table help custom',
              menu: {
                custom: {
                  title: 'Variables',
                  items: 'title fullName firstNames lastName loginLink loginButton unsubscribe'
                }
              },
              toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
              toolbar_sticky: true,
              autosave_ask_before_unload: true,
              autosave_interval: '30s',
              autosave_prefix: '{path}{query}-{id}-',
              autosave_restore_when_empty: false,
              autosave_retention: '2m',
              image_advtab: true,
              link_list: [
                {title: 'My page 1', value: 'https://www.tiny.cloud'},
                {title: 'My page 2', value: 'http://www.moxiecode.com'}
              ],
              image_list: [
                {title: 'My page 1', value: 'https://www.tiny.cloud'},
                {title: 'My page 2', value: 'http://www.moxiecode.com'}
              ],
              image_class_list: [
                {title: 'None', value: ''},
                {title: 'Some class', value: 'class-name'}
              ],
              importcss_append: true,
              file_picker_callback: function (callback, value, meta) {
                /* Provide file and text for the link dialog */
                if (meta.filetype === 'file') {
                  callback('https://www.google.com/logos/google.jpg', {text: 'My text'});
                }

                /* Provide image and alt text for the image dialog */
                if (meta.filetype === 'image') {
                  callback('https://www.google.com/logos/google.jpg', {alt: 'My alt text'});
                }

                /* Provide alternative source and posted for the media dialog */
                if (meta.filetype === 'media') {
                  callback('movie.mp4', {source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg'});
                }
              },
              // templates: [
              //   {
              //     title: `${started.name}`,
              //     description: `${started.description}`,
              //     content: started.version && started.version.template
              //   },
              // ],
              template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
              template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
              height: 600,
              table_column_resizing: 'preservetable',
              image_caption: true,
              quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
              noneditable_noneditable_class: 'mceNonEditable',
              toolbar_mode: 'sliding',
              contextmenu: 'link image imagetools table',
              skin: useDarkMode ? 'oxide-dark' : 'oxide',
              content_css: useDarkMode ? 'dark' : 'default',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              setup: function (editor) {
                const toggleState = false;

                editor.ui.registry.addMenuItem('title', {
                  text: 'Title',
                  onAction: function () {
                    editor.insertContent('%recipient.title%');
                  }
                });

                editor.ui.registry.addMenuItem('fullName', {
                  text: 'Full Name',
                  onAction: function () {
                    editor.insertContent('%recipient.fullName%');
                  }
                });

                editor.ui.registry.addMenuItem('firstNames', {
                  text: 'First Names',
                  onAction: function () {
                    editor.insertContent('%recipient.firstNames%');
                  }
                });

                editor.ui.registry.addMenuItem('lastName', {
                  text: 'Last Name',
                  onAction: function () {
                    editor.insertContent('%recipient.lastName%');
                  }
                });
                editor.ui.registry.addMenuItem('loginLink', {
                  text: 'Login Link',
                  onAction: function () {
                    editor.insertContent('<a href=' + FRONTEND_URL + '/login?uuid=%recipient.uuid%>login link</a>');
                  }
                });
                editor.ui.registry.addMenuItem('loginButton', {
                  text: 'Login Button',
                  onAction: function () {
                    editor.insertContent('<a href=' + FRONTEND_URL + '/login?uuid=%recipient.uuid% class="btn-primary" style="font-family: Helvetica Neue,Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Login Button</a>');
                  }
                });

                editor.ui.registry.addMenuItem('unsubscribe', {
                  text: 'Unsubscribe',
                  onAction: function () {
                    editor.insertContent('<a href="%mailing_list_unsubscribe_url%">Unsubscribe</a>');
                  }
                });
              },
            }}
          /></>}
      <div className={"button-create"}>
        <Button
          onClick={() => handlerSaveTemplate()}
          primary
          loading={isLoading}
        >
          Create template
        </Button>
        <Button
          onClick={() => {
            history.push('/new-email');
          }}
          basic
          color="red"
          className="ez-basic-btn"
        >
          Cancel
        </Button>
      </div>
      <Modal
        size={"mini"}
        open={openErrorMassage}
        onClose={() => setOpenErrorMassage(false)}
        onOpen={() => setOpenErrorMassage(true)}
      >
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenErrorMassage(false)}>OK</Button>
        </Modal.Actions>
      </Modal>
    </div>

  );
};

export default NewTemplate;