import React, { useState } from 'react';
import { Table, Button } from 'semantic-ui-react';
import './PersonRow.css';
import Highlighter from 'react-highlight-words';
import cn from 'classnames';
import CustomerForm from './CustomerForm/CustomerForm';
import { useContext } from 'react';
import { EditCustomerContext } from '../contexts/EditCustomerProvider';
import {
  getCustomerInfo,
  updateCustomerAdmin,
} from '../services/customerService';
import EZErrorModal from './ErrorModal/ErrorModal';

const buttonLabel = (openFlag) => (openFlag ? 'Close' : 'View');

export const clean = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
  return obj;
};

const PersonRow = ({
  customerId,
  firstNames,
  lastName,
  title,
  addressLine1,
  searchTerm,
  open,
  setCurrentlyOpen,
  selectNewCustomer,
  selected,
	uuid,
}) => {
  const [openValidationFailedModal, setOpenValidationFailedModal] = useState(
    false
  );
  const [customerFirstNames, setCustomerFirstNames] = useState(firstNames);
  const [customerLastName, setCustomerLastName] = useState(lastName);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [customerAddressLine2, setCustomerAddressLine2] = useState(null);
  const [customerAddress, setCustomerAddress] = useState(null);
  const [customerAddressLine1, setCustomerAddressLine1] = useState(
    addressLine1
  );
  const [customerDefaultGreeting, setCustomerDefaultGreeting] = useState(null);
  const [customerPhone, setCustomerPhone] = useState(null);
  const [customerInternalComment, setCustomerInternalComment] = useState(null);
  const [customerStaff, setCustomerStaff] = useState(null);
  const [customerStaffName, setCustomerStaffName] = useState(null);
  const [customerStaffPosition, setCustomerStaffPosition] = useState(null);
  const [customerStaffCategoryIds, setCustomerStaffCategoryIds] = useState(
    null
  );
  const [customerTitleId, setCustomerTitleId] = useState(null);
  const [customerTitle, setCustomerTitle] = useState(title);
  const [customerReciprocity, setCustomerReciprocity] = useState(null);
  const [customerCardOnly, setCustomerCardOnly] = useState(null);
  const [customerEmailOnly, setCustomerEmailOnly] = useState(null);
  const [customerPaperOrder, setCustomerPaperOrder] = useState(null);
  const [customerDisabled, setCustomerDisabled] = useState(null);
  const [customerAdmin, setCustomerAdmin] = useState(null);
  const [customerNewCustomer, setCustomerNewCustomer] = useState(null);
  const [customerInternal, setCustomerInternal] = useState(null);
  const [
    customerBasketWithoutOrders,
    setCustomerBasketWithoutOrders,
  ] = useState(null);
  const [
    customerCheckCustomer,
    setCustomerCheckCustomer,
  ] = useState(null);
  const [openSaveFailedModal, setOpenSaveFailedModal] = useState(false);
  const [saveFailedError, setSaveFailedError] = useState('');

  const {
    editInProgress,
    setEditInProgress,
    saveEditInProgress,
    setSaveEditInProgress,
    cancelEditInProgress,
    setCancelEditInProgress,
    setErrors,
  } = useContext(EditCustomerContext);
  const fullName = `${customerLastName}, ${customerTitle} ${customerFirstNames}`;

  const [greetingSuffix, setGreetingSuffix] = useState(null)



  const updateCustomer = () => {
    (async () => {
      setSaveEditInProgress(true);
      const { errors } = await updateCustomerAdmin(
        clean({
          customerId,
          firstNames: customerFirstNames,
          lastName: customerLastName,
          email: customerEmail,
          titleId: customerTitleId,
          defaultGreeting: customerDefaultGreeting,
          fullAddress: customerAddress,
          phone: customerPhone,
          internalComment: customerInternalComment,
          reciprocation: customerReciprocity,
          cardOnly: customerCardOnly,
          emailOnly: customerEmailOnly,
          basketWithoutOrders: customerBasketWithoutOrders,
          checkCustomer: customerCheckCustomer,
          paperOrder: customerPaperOrder,
          disabled: customerDisabled,
          admin: customerAdmin,
          newCustomer: customerNewCustomer,
          internal: customerInternal,
          staff: customerStaff,
          staffName: customerStaffName,
          staffPosition: customerStaffPosition,
          staffCategoryIds: customerStaffCategoryIds,
          addressLine2: customerAddressLine2,
          greetingSuffix:greetingSuffix,
        })
      );
      if (errors) {
        console.log('Something went wrong...');
        setTimeout(() => {
          setSaveEditInProgress(false);
          setSaveFailedError(errors.message);
          setOpenSaveFailedModal(true);
        }, 300);
        return;
      }

      const customerInfo = await getCustomerInfo({ customerId });
      const { firstNames, lastName, titleId, addressLine1 } = customerInfo;
      setCustomerLastName(lastName);
      setCustomerFirstNames(firstNames);
      setCustomerTitleId(titleId);
      setCustomerAddressLine1(addressLine1);

      setTimeout(() => {
        setSaveEditInProgress(false);
        setEditInProgress(false);
        setCurrentlyOpen(-1);
      }, 300);
    })();
  };

  const validateFields = () => {
    const errors = {};

    const fieldsToValidate = clean({
      firstNames: customerFirstNames,
      lastName: customerLastName,
      addres: customerAddress,
      defaultGreeting: customerDefaultGreeting,
    });

    Object.entries(fieldsToValidate).forEach(([key, value = '']) => {
      if (value.trim().length === 0) {
        errors[key] = 'Required field';
      }
    });

    setErrors(errors);
    return errors;
  };

  return (
    <Table.Row className={'record-table-row'}>
      <Table.Cell onClick={selectNewCustomer && (() => {selectNewCustomer(customerId)})} className={cn({ pointer: selectNewCustomer, selected: selected })} >
        <EZErrorModal
          headerText="Validation Failed"
          open={openValidationFailedModal}
          setOpen={setOpenValidationFailedModal}
        >
          <p> Some fields contain invalid information. </p>
          <p> Please, scroll up, fix the issues and try saving again. </p>
        </EZErrorModal>
        <EZErrorModal
          headerText="Save Failed"
          open={openSaveFailedModal}
          setOpen={setOpenSaveFailedModal}
        >
          {saveFailedError}
        </EZErrorModal>

        <div className={cn('add-remove-customer')}>
          {open ? (
            <CustomerForm
              customerId={customerId}
              setCustomerFirstNames={setCustomerFirstNames}
              setCustomerLastName={setCustomerLastName}
              setCustomerEmail={setCustomerEmail}
              setCustomerPhone={setCustomerPhone}
              setCustomerInternalComment={setCustomerInternalComment}
              setCustomerTitleId={setCustomerTitleId}
              setCustomerTitle={setCustomerTitle}
              setCustomerDefaultGreeting={setCustomerDefaultGreeting}
              setCustomerStaff={setCustomerStaff}
              setCustomerStaffName={setCustomerStaffName}
              setCustomerStaffCategoryIds={setCustomerStaffCategoryIds}
              setCustomerStaffPosition={setCustomerStaffPosition}
              updateCustomer={updateCustomer}
              setCustomerAddress={setCustomerAddress}
              setCustomerAddressLine2={setCustomerAddressLine2}
              setCustomerReciprocity={setCustomerReciprocity}
              setCustomerCardOnly={setCustomerCardOnly}
              setCustomerEmailOnly={setCustomerEmailOnly}
              setCustomerBasketWithoutOrders={setCustomerBasketWithoutOrders}
              setCustomerCheckCustomer={setCustomerCheckCustomer}
              setCustomerPaperOrder={setCustomerPaperOrder}
              setCustomerDisabled={setCustomerDisabled}
              setCustomerAdmin={setCustomerAdmin}
              setCustomerNewCustomer={setCustomerNewCustomer}
              setCustomerInternal={setCustomerInternal}
              isNewCustomer={!!selectNewCustomer}
              uuid={uuid}
              setGreetingSuffix={setGreetingSuffix}
            />
          ) : (
            <div className="name-address-content">
              <div className="name-inside-cell">
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={searchTerm.split(' ')}
                  autoEscape={true}
                  textToHighlight={fullName}
                />
              </div>
              <div className="address-inside-cell">
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={searchTerm.split(' ')}
                  autoEscape={true}
                  textToHighlight={customerAddressLine1}
                />
              </div>
            </div>
          )}
          <div>
            {(editInProgress && open) || (
              <Button
                className="view-close-save-btn"
                disabled={!open && editInProgress}
                basic
                color="blue"
                onClick={() => {
                  if (open) {
                    setCurrentlyOpen(-1);
                    return;
                  }
                  setCurrentlyOpen(customerId);
                }}
              >
                {buttonLabel(open)}
              </Button>
            )}
            {open && editInProgress && (
              <div className="save-cancel-container">
                <Button
                  className="save-btn"
                  primary
                  onClick={() => {
                    const errors = validateFields();
                    console.log({ errors });
                    if (Object.keys(errors).length === 0) {
                      updateCustomer();
                    } else {
                      setOpenValidationFailedModal(true);
                    }
                  }}
                  loading={saveEditInProgress}
                >
                  Save
                </Button>

                <Button
                  className="cancel-btn ez-basic-btn"
                  basic
                  loading={cancelEditInProgress}
                  color="blue"
                  onClick={async () => {
                    setCancelEditInProgress(true);
                    setErrors({});
                    const customerInfo = await getCustomerInfo({ customerId });
                    const {
                      firstNames,
                      lastName,
                      titleId,
                      addressLine1,
                    } = customerInfo;
                    setCustomerLastName(lastName);
                    setCustomerFirstNames(firstNames);
                    setCustomerTitleId(titleId);
                    setCustomerAddressLine1(addressLine1);

                    setTimeout(() => {
                      setCancelEditInProgress(false);
                      setEditInProgress(false);
                      setCurrentlyOpen(-1);
                    }, 300);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default PersonRow;
