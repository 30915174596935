import React, {useState} from 'react';
import styles from "./SendEmailScreen.css"
import {Button, Input, Modal, Icon, Popup, Dropdown, Label} from "semantic-ui-react";
import {BACKEND_URL, JWT_KEY, YEAR_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {toast} from "react-toastify";

const SendEmail = ({selectTemplate}) => {

  const [custom, setCustom] = useState("");
  const [openMassageModal, setOpenMassageModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [nameCustomer, setNameCustomer] = useState("");
  const [template, setTemplate] = useState("");
  const [selectNameCustomer, setSelectNameCustomer] = useState([]);
  const [subject, setSubject] = useState("");
  const [tag, setTag] = useState("");
  const [errorInput, setErrorInput] = useState(false);

  const emailSetSelect = {
    options: [
      {value: 'all', text: 'All'},
      {value: 'past_givers', text: 'Past Givers Not Giving Yet'},
      {value: 'basket_receivers', text: 'Basket Receivers'},
      {value: 'custom', text: 'Custom'},
    ],
    selected: '',
  };

  const getSelect = (value) => {
    fetch(`${BACKEND_URL}api/v1/customer/admin/emails?type=${value}&year=${getWithExpiry(YEAR_KEY)}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setSelectNameCustomer(result.map((i) => ({
          text: i.name,
          value: i.customerId,
        })));
      })

      .catch((error) => console.log('No way! ', error));
  }

  const sendEmail = async (item) => {
    const response = await fetch(
      `${BACKEND_URL}api/v1/email/mailing`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
        body: JSON.stringify(item),
      }
    ).catch((err) => {
      console.error(`Error sending email:`, err);
      throw new Error(err);

    });

    if (!response.ok) {
      const errors = await response;
      console.log(errors.status)
      setModalText(`Error status: ${errors.status}`)
      setOpenMassageModal(true)
      console.log('ERROR in response (sending email): ', errors);
      return {errors};
    }

    const data = await response.json();
    console.log('RESULT: ', data);
    return data;

  }


  const handleEmailChange = (event, {value}) => {
    setCustom(value);
    if (value === "custom") {
      getSelect("all");
    } else {
      getSelect(`${value}`);
    }
  };

  const handleNameChange = (event, {value}) => {
    setNameCustomer(value);
    setErrorInput(false)
  };

  const handleTemplateChange = (event, {value}) => {
    setTemplate(value);
    setTag(value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  // const handleTagChange = (e) => {
  //   setTag(e.target.value);
  // };

  const combineEmail = () => {
    if(custom==="custom" && errorInput){
      setErrorInput(true);
    } else if(!subject) {
      setErrorInput(true);
    } else {
      const obj = {
        customerIds: custom === "custom" ? nameCustomer : null,
        subject: subject,
        tag: tag,
        templateName: template,
        type: custom,
      }
      sendEmail(obj).then(()=> toast.dark("Email successfully sent!"))
    }
  }


  return (
    <div className={styles["content-table"]}>
      {/* <h2 style={{marginTop: '35px'}} className={styles["title-email"]}>Email</h2> */}
      <div className={"email-addresses"}>
     
        <Dropdown
          options={emailSetSelect.options}
          placeholder='Select a list of emails'
          onChange={handleEmailChange}
          icon='user'
          className='icon'
          labeled
          button
        />
        <Dropdown
          icon='file outline'
          button
          labeled
          className='icon'
          options={selectTemplate}
          placeholder='Select a template'
          onChange={handleTemplateChange}
        />
        <Input
          type={"text"}
          icon='write'
          placeholder={"Subject"}
          onChange={(e) => {
            handleSubjectChange(e)
          }}
          error={subject ? false : errorInput}
        />
        
        {/* <Input
          type={"text"}
          icon='tag'
          placeholder={"Tag"}
          labelPosition='right corner'
          label={
            <Label>
              <label />
              <Popup
                content="A tag allows us to track individual emails for how many were opened, sent to spam, etc...  Enter a unique tag to track this email with. Keep it small and descriptive e.g. welcomeemail. A date and time will be automatically added to the tag."
                trigger={<Icon name='info circle' />}
              />
            </Label>
          }
          onChange={(e) => {
            handleTagChange(e)
          }}
          error={tag ? false : errorInput}
        /> */}
      </div>

      <Dropdown
        style={{marginTop: "35px"}}
        disabled={custom !== "custom"}
        placeholder='Start typing to select multiple custom names.'
        fluid
        multiple
        search
        selection
        options={selectNameCustomer}
        onChange={handleNameChange}
        error={custom ==="custom" ? errorInput : false}
      />
      <div>
        <Button
          disabled={(!custom || !template)}
          color='green'
          onClick={combineEmail}
          style={{marginBottom: "35px", marginTop: "35px", width: "124px"}}
        >
          Send
        </Button>
      </div>
      <Modal
        size={"mini"}
        open={openMassageModal}
        onClose={() => setOpenMassageModal(false)}
        onOpen={() => setOpenMassageModal(true)}
      >
        <Modal.Header>Send email</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {modalText}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color={"green"}
                  onClick={() => setOpenMassageModal(false)}>OK</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};


export default SendEmail;