import { BACKEND_URL, JWT_KEY } from '../config';
import { getWithExpiry } from './localStorageService';

export const deleteCustomerAdmin = async (customerId) => {
  const response = await fetch(
    `${BACKEND_URL}api/v1/customer/admin/${customerId}`,
    {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    }
  ).catch((err) => {
    console.error(`Error deleteCustomerAdmin:`, err);
    return { errors: err };
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.log('ERROR in response (deleteCustomerAdmin): ', errorText);
    return { errors: errorText };
  }

  const data = await response.json();
  console.log('RESULT (delete customer): ', data);
  return { data };
};

export const updateCustomerAdmin = async (customer) => {
  const response = await fetch(
    `${BACKEND_URL}api/v1/customer/admin/${customer.customerId}`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
      body: JSON.stringify(customer),
    }
  ).catch((err) => {
    console.error(`Error updateCustomerAdmin:`, err);
    return { errors: err };
  });

  if (!response.ok) {
    const errors = await response.json();
    console.log('ERROR in response (updateCustomerAdmin): ', errors);
    return { errors };
  }

  const data = await response.json();
  console.log('RESULT: ', data);
  return { data };
};

export const createCustomerAdmin = async (customer) => {
  const response = await fetch(`${BACKEND_URL}api/v1/customer/admin`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
    body: JSON.stringify(customer),
  }).catch((err) => {
    console.error(`Error createCustomerAdmin:`, err);
    return { errors: err };
  });

  if (!response.ok) {
    const errors = await response.json();
    console.log('ERROR in response (createCustomerAdmin): ', errors);
    return { errors };
  }

  const data = await response.json();
  console.log('Created customer: ', data);
  return { data };
};

export const updateCustomer = async ({
  titleId,
  firstNames,
  lastName,
  fullAddress,
  addressLine2,
  phone,
  reciprocation,
}) => {
  const response = await fetch(`${BACKEND_URL}api/v1/customer`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
    body: JSON.stringify({
      titleId,
      firstNames,
      lastName,
      fullAddress,
      addressLine2,
      phone,
      reciprocation,
    }),
  }).catch((err) => {
    console.error(`Error updateCustomer:`, err);
  });

  if (!response.ok) {
    console.log('ERROR in response (updateCustomer): ', response);
    return;
  }

  const result = await response.json();
  return { result };
};

export const createCustomer = async ({
  addressLine2,
  email,
  firstNames,
  fullAddress,
  lastName,
  newPassword,
  orgId,
  phone,
  pin,
  reciprocation,
  titleId,
}) => {
  const response = await fetch(`${BACKEND_URL}api/v1/auth/create`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      addressLine2,
      email,
      firstNames,
      fullAddress,
      lastName,
      newPassword,
      orgId,
      phone,
      pin,
      reciprocation,
      titleId,
    }),
  }).catch((err) => {
    console.error(`Error createCustomer:`, err);
  });

  if (!response.ok) {
    console.log('ERROR in response (createCustomer): ', await response.text());
    throw new Error(response);
  }

  const result = await response.text();
  return { result };
};

export const getCustomerInfo = async ({ customerId }) => {
  const response = await fetch(`${BACKEND_URL}api/v1/customer/${customerId}`, {
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
  }).catch((err) => {
    console.error('Error:', err);
  });

  if (!response.ok) {
    console.log('ERROR Response: ', response);
    return;
  }

  const accountInfo = await response.json();
  return accountInfo;
};

export const getNewCustomers = async () => {
  const response = await fetch(`${BACKEND_URL}api/v1/customer/admin/newCustomers`, {
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
  }).catch((err) => {
    console.error('Error:', err);
  });

  if (!response.ok) {
    console.log('ERROR Response: ', response);
    return;
  }

  const customers = await response.json();
  return customers;
};

export const mergeCustomersAdmin = async (customer, customerID) => {
  console.log(`${BACKEND_URL}api/v1/customer/admin/merge?customerToKeepId=${customerID}`, "customerID", customerID, "JSON.stringify(customer)", JSON.stringify(customer) )
  const response = await fetch(
    `${BACKEND_URL}api/v1/customer/admin/merge?customerToKeepId=${customerID}`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
      body: JSON.stringify(customer),
    }
  ).catch((err) => {
    console.error(`Error updateCustomerAdmin:`, err);
    return { errors: err };
  });

  if (!response.ok) {
    const errors = await response.json();
    console.log('ERROR in response (updateCustomerAdmin): ', errors);
    return { errors };
  }

  const data = await response.json();
  console.log('RESULT: ', data);
  return { data };
};
