import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import PersonRow from './PersonRow';
import './PeopleTable.css';

const PeopleTable = ({ people, searchTerm, selections, setSelections, selectNewCustomer, selectedNewCustomer }) => {
  const [currentlyOpen, setCurrentlyOpen] = useState(-1);
  const [isEditInProgress, setEditInProgress] = useState(false);

  return (
    <Table className="people-table" celled>
      <Table.Body>
        {people.map((person) => (
          <PersonRow
            {...person}
            key={person.customerId}
            searchTerm={searchTerm}
            open={currentlyOpen === person.customerId}
            setCurrentlyOpen={setCurrentlyOpen}
            isEditInProgress={isEditInProgress}
            setEditInProgress={setEditInProgress}
            selectNewCustomer={selectNewCustomer}
            selected={selectedNewCustomer === person.customerId}
            uuid={person.uuid}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default PeopleTable;
