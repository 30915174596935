import {Line} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {BACKEND_URL, JWT_KEY, YEAR_KEY} from "../../../config";
import {getWithExpiry} from "../../../services/localStorageService";

const LineChart = () => {
	
	const [revenue, setRevenue] = useState([]);
	useEffect(() => {
		fetch(`${BACKEND_URL}api/v1/reports/revenue/running?year=${getWithExpiry(YEAR_KEY)}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setRevenue(result)
			})
			
			.catch((error) => console.log('No way! ', error));
		
	}, [])

	const colors = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a']
	var datasetData = []
	const groups = revenue.reduce((acc, item) => {
		if (!acc[item.year]) {
		  acc[item.year] = [];
		}
	  
		acc[item.year].push(item);
		return acc;
	}, {})
	
	const groupObject = Object.values(groups)
	var color = 0
	groupObject.forEach(function (item, index) {
		let totals = item.map(i => {
			return {x: i.date, y: i.total}
		});
		let year = item.reduce((uniqueYear, i) => i.year, 0)
		var dataset = {
			label: year,
			data: totals,
			fill: false,
			borderColor: colors[color],
			tension: 0.1,
		}
		datasetData.push(dataset)
		color++
	});


	let dates = revenue.map(item => {
		return item.date.toString()
	});


	function uniqueDates(value, index, self) {
		return self.indexOf(value) === index;
	}

	const labels = dates.filter(uniqueDates).sort()
	console.log(labels)
	const data = {
		labels: labels,
		datasets: datasetData
	};
	
	const config = {
		type: 'line',
		data: data,
		options: {
			plugins: {
				title: {
					display: true,
					text: 'Running Revenue'
				},
				legend: {
					display: true
				},
				datalabels: {
					formatter: function (value, context) {
						return parseFloat(value.y).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2});
					}
				}
			}
		}
	};
	return (
		<>
			<Line data={data} options={config.options} height={"200px"} width={"700px"} />
		</>
	)
};
export default LineChart
