import React, {useEffect, useState} from 'react';
import {BACKEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {Button, Checkbox, Input, Modal, Table} from "semantic-ui-react";
import "./Settings.css"
import {toast} from "react-toastify";

const Settings = () => {
  const [settings, setSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [error, setError] = useState("")
  const [openErrorMassage, setOpenErrorMassage] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetch(`${BACKEND_URL}api/v1/org/admin`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setSettings(result);
        setIsLoading(false);
      })

      .catch((error) => console.log('No way! ', error));

  }, [])

  const updateSettings = async (settings) => {
    const response = await fetch(
      `${BACKEND_URL}api/v1/org/admin`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
        body: JSON.stringify(settings),
      }
    ).catch((err) => {
      console.error(`Error updateSettings:`, err);
      return {errors: err};
    });
    if (!response.ok) {
      const errors = await response.json();
      setError(errors.status);
      setOpenErrorMassage(true);
      console.log('ERROR in response (updateSettings): ', errors);
      return errors;
    }
    const data = await response.json();
    console.log('RESULT: ', data);
    setOpen(true);
    setTimeout(()=>{
      setOpen(false)
    }, 2000)
    return data;

  };

  const handleChangeValue = (value, key) => {
    const settingsConst = {...settings}
    settingsConst[key] = value;
    setSettings(settingsConst);
  };

  useEffect(() => {
    setSettings(settings)
  }, [settings])

  const handleChangeSave = () => {
    updateSettings(settings).then(()=> toast.dark("Save success!"))

  };

  const settings_map = {
    "name": {
      "name": "Name",
      "value": settings.name
    },
    "address": {
      "name": "Address",
      "value": settings.address
    },
    "city": {
      "name": "City",
      "value": settings.city
    },
    "state": {
      "name": "State",
      "value": settings.state
    },
    "zip": {
      "name": "Zip",
      "value": settings.zip
    },
    "phone": {
      "name": "Phone",
      "value": settings.phone
    },
    "subTitle": {
      "name": "Sub Title",
      "value": settings.subTitle
    },
    "administatorEmail": {
      "name": "Administrator Email",
      "value": settings.administatorEmail
    },
    "emailDomain": {
      "name": "Email Domain",
      "value": settings.emailDomain
    },
    "activeYear": {
      "name": "Active Year",
      "value": settings.activeYear
    },
    "activeYearPrice": {
      "name": "Active Year Price",
      "value": settings.activeYearPrice
    },
    "bigSize": {
      "name": "Size for Large",
      "value": settings.bigSize
    },
    "minSpent": {
      "name": "Amount a customer needs to spend to atomatically get a large",
      "value": settings.minSpent
    },
    "stripePublishableKey": {
      "name": "Stripe Publishable Key",
      "value": settings.stripePublishableKey
    },
    "stripeAccessKey": {
      "name": "Stripe Access Key",
      "value": settings.stripeAccessKey
    },
    "defaultGreeting": {
      "name": "Default Greeting",
      "value": settings.defaultGreeting
    }
  }

  const boolean_map = {
    "orderingActive": {
      "name": "Enable Ordering",
      "value": settings.orderingActive
    },
    "hurryActive": {
      "name": "Activate Hurry Message",
      "value": settings.hurryActive
    },
    "runScheduledTasks": {
      "name": "Run Scheduled Tasks",
      "value": settings.runScheduledTasks
    },
  }

  const messages_map = {
    "hurryMessage": {
      "name": "Hurry Message",
      "value": settings.hurryMessage
    },
    "subTitle": {
      "name": "Subtitle",
      "value": settings.subTitle
    },
    "pickupMessage": {
      "name": "Pickup Message",
      "value": settings.pickupMessage
    },
  }

  return (

    <div className="content-table">
      {isLoading ? <h3 style={{margin: "50px", textAlign: "center"}}>Loading...</h3> :
        <>
          <h1 className="title">Settings</h1>
          <Table color={"blue"}>
            <Table.Header textAlign={"center"}>
              <Table.Row>
                <Table.HeaderCell textAlign={"center"} colSpan="2">
                  General Settings
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(settings_map).map(([key, value]) => (<Table.Row key={key}>
                <Table.HeaderCell>{value.name}</Table.HeaderCell>
                <Table.Cell>
                  <Input className="input-settings"
                         value={value.value}
                         onChange={(e) => handleChangeValue(e.target.value, key)}/>
                </Table.Cell>
              </Table.Row>))}
            </Table.Body>
          </Table>
          <Table color={"green"}>
            <Table.Header textAlign={"center"}>
              <Table.Row>
                <Table.HeaderCell textAlign={"center"} colSpan="2">
                  Activations
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(boolean_map).map(([key, value]) => (<Table.Row key={key}>
                <Table.HeaderCell>{value.name}</Table.HeaderCell>
                <Table.Cell textAlign="left">
                  <Checkbox
                    onChange={(e, data) => handleChangeValue(data.checked, key)}
                    checked={value.value}/>
                </Table.Cell>
              </Table.Row>))}

            </Table.Body>
          </Table>
          <Table color={"orange"}>
            <Table.Header textAlign={"center"}>
              <Table.Row>
                <Table.HeaderCell textAlign={"center"} colSpan="2">
                  Messages
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(messages_map).map(([key, value]) => (<Table.Row key={key}>
                <Table.HeaderCell>{value.name}</Table.HeaderCell>
                <Table.Cell>
                  <Input className="input-settings"
                         value={value.value}
                         onChange={(e) => handleChangeValue(e.target.value, key)}/>
                </Table.Cell>
              </Table.Row>))}
            </Table.Body>
          </Table>

          <div className="button-download-item">

            <Button onClick={() => {
              handleChangeSave()
            }}
                    fluid
                    className="download-icon"
                    basic color='blue'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
						 <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
               Save
              </span>
              </div>
            </Button>
          </div>
        </>
      }

      <Modal
        size={"mini"}
        open={openErrorMassage}
        onClose={() => setOpenErrorMassage(false)}
        onOpen={() => setOpenErrorMassage(true)}
      >
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenErrorMassage(false)}>OK</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default Settings;
