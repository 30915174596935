import {Bar} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {BACKEND_URL, JWT_KEY} from "../../../config";
import {getWithExpiry} from "../../../services/localStorageService";

Chart.register(ChartDataLabels);

const OrdersGivenVerticalBar = () => {
	
	const [orders, setOrders] = useState([]);
	
	useEffect(() => {
		fetch(`${BACKEND_URL}api/v1/reports/count/consolidated?year=0`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setOrders(result)
			})
			
			.catch((error) => console.log('No way! ', error));
		
	}, [])
	
	
	useEffect(() => {
		setOrders(orders)
	}, [orders])
	
	
	const years = orders.map(item => {
		return item.year.toString()
	});
	const totalOrdersGiven = orders.map(item => {
		return item.totalOrdersGiven
	});
	const totalRecipOrdersGiven = orders.map(item => {
		return item.totalRecipOrdersGiven
	});
	const totalNonRecipOrdersGiven = orders.map(item => {
		return item.totalNonRecipOrdersGiven
	});
	
	const color_scheme = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a']
	const colors = color_scheme.slice(0, 5);
	const data = {
		labels: years,
		datasets: [
			{
				label: 'Recip Orders Given',
				data: totalRecipOrdersGiven,
				backgroundColor: colors[0],
				minBarLength: 30
			},
			{
				label: 'Non Recip Orders Given',
				data: totalNonRecipOrdersGiven,
				backgroundColor: colors[1],
				minBarLength: 50
			},
			{
				label: 'Total Orders Given',
				data: totalOrdersGiven,
				backgroundColor: colors[2],
				minBarLength: 60
			},
		]
	};
	
	const config = {
		type: 'bar',
		data: data,
		options: {
			plugins: {
				title: {
					display: true,
					text: 'Orders Given'
				},
				datalabels: {
					color: 'white',
					font: {
						weight: 'bold'
					},
					anchor: 'end',
					align: 'bottom',
					display: 'auto'
				}
				// legend: {
				//   display: false
				// }
			},
			scales: {
				x: {
					title: {
						display: true,
						text: 'Year'
					},
					stacked: true
				},
				y: {
					beginAtZero: true,
					
				},
			},
		},
	};
	
	
	return (
		<>
			<Bar data={data} options={config.options} height={"300px"}/>
		</>
	)
};
export default OrdersGivenVerticalBar
