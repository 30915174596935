import React from 'react';
import NewCustomerForm from '../NewCustomerForm/NewCustomerForm';
import './NewCustomerScreen.css';

const NewCustomerScreen = () => {
  return (
    <div className="new-customer-screen">
      <NewCustomerForm />
    </div>
  );
};

export default NewCustomerScreen;
