import { BACKEND_URL, JWT_KEY } from '../config';
import { setWithExpiry } from './localStorageService';

export const invisibleLogin = async ({ email, password, setAuthenticated }) => {
  const response = await fetch(`${BACKEND_URL}api/v1/auth/login`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  }).catch((err) => {
    console.error('Error:', err);
    return false;
  });

  if (!response.ok) {
    console.error('Could not login: ', await response.text());
    return false;
  }

  const {
    accessToken,
    userId,
    expiresIn,
    message: loginErrorMessage,
  } = await response.json();

  if (loginErrorMessage) {
    console.log('Could not log in: ', loginErrorMessage);
    return false;
  }

  setWithExpiry(JWT_KEY, accessToken, expiresIn);
  setAuthenticated(true);

  return true;
};

export const uuidLogin = async ({ uuid, setAuthenticated }) => {
  const response = await fetch(`${BACKEND_URL}api/v1/auth/login?uuid=${uuid}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Referer: 'https://ezpurim.herokuapp.com',
    },
  }).catch((err) => {
    console.log('Error during login: ', err);
    return false;
  });

  if (!response.ok) {
    console.error('Could not login: ', await response.text());
    return false;
  }

  const {
    accessToken,
    expiresIn,
    message: loginErrorMessage,
  } = await response.json();

  if (loginErrorMessage) {
    console.log('Could not log in: ', loginErrorMessage);
    return false;
  }

  setWithExpiry(JWT_KEY, accessToken, expiresIn);
  setAuthenticated(true);

  return true;
};
