import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { BACKEND_URL } from '../../config';
import './TitlesCombo.css';

const TitlesCombo = ({ initialTitleId = 1, handleTitleIdChange }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchTitles = async () => {
      const response = await fetch(`${BACKEND_URL}api/v1/title/all`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip',
        },
      });
      let data = (await response.json()).sort(
        ({ title }, { title: otherTitle }) => {
          if (title < otherTitle) {
            return -1;
          }
          if (title > otherTitle) {
            return 1;
          }

          return 0;
        }
      );
      data = data.map(({ title, titleId }) => ({
        key: titleId,
        text: title,
        value: titleId,
      }));
      setData(data);
    };
    fetchTitles();
  }, []);

  const handleChange = (e, { value: titleId }) => {
    console.log({ titleId, data });
    const { text: title } = data.find(({ value }) => value === titleId);
    console.log({ title, titleId });
    handleTitleIdChange({ titleId, title });
  };

  return (
    <div>
      <label className="code">Titles</label>
      <Dropdown
        onChange={handleChange}
        className="ezinput title-combo-dropdown"
        placeholder="Select Title"
        fluid
        search
        selection
        options={data}
        defaultValue={initialTitleId}
      />
    </div>
  );
};

export default TitlesCombo;
