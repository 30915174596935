import React, {useState} from 'react';
import {Confirm, Grid, Image, Modal, Button, Loader, Dimmer} from "semantic-ui-react";
import recipImage from '../../images/reciprocate.png';
import resetImage from '../../images/reset.png';
import {BACKEND_URL, JWT_KEY} from "../../config";
import {toast} from "react-toastify";
import {getWithExpiry} from "../../services/localStorageService";
import {CSVLink} from "react-csv";
import {Parser} from "json2csv";

const Tools = () => {
	const [openConfirm, setOpenConfirm] = useState(false);
	const [running, setRunning] = useState(false);
	const [openRecipConfirm, setOpenRecipConfirm] = useState(false);
	const [recipData, setRecipData] = useState();
	const [openMessageModal, setOpenMessageModal] = useState();

	const json2csvParser = new Parser();

	const runReciprocations = async () => {
		setOpenConfirm(false);
		setRunning(true)
		const response = await fetch(
			`${BACKEND_URL}api/v1/payment/admin/reciprocation/charge?customerId=0`,
			{
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
				},
			}
		).catch((err) => {
			console.error(`Error running reconciliations:`, err);
			return {errors: err};
		});
		
		if (!response.ok) {
			const errors = await response.json();
			console.log('ERROR in response (reconciliations): ', errors);
			return {errors};
		}
		
		const data = await response.json();
		console.log('RESULT: ', data);
		toast.dark('Running reciprocations success!', {
			position: "bottom-right",
			autoClose: 3000,
			hideProgressBar: true
		})
		
		setRunning(false)
		if(data.size>0) {
			setRecipData(json2csvParser.parse(data));
			setOpenMessageModal(true);
		}
	}

	const runSetReciprocations = async () => {
		setOpenRecipConfirm(false);
		setRunning(true)
		const response = await fetch(
			`${BACKEND_URL}api/v1/customer/reset/reciprocations`,
			{
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
				},
			}
		).catch((err) => {
			console.error(`Error running reset reciprocations:`, err);
			return {errors: err};
		});
		
		if (!response.ok) {
			const errors = await response.json();
			console.log('ERROR in response (reset reciprocations): ', errors);
			return {errors};
		}
		
		const data = await response.json();
		console.log('RESULT: ', data);
		toast.dark('Reseting reciprocations success!', {
			position: "bottom-right",
			autoClose: 3000,
			hideProgressBar: true
		})
		
		setOpenRecipConfirm(false);
		setRunning(false)
	}

	return (
		<div className="content-table">
			<Grid columns={3} celled='internally'>
				<Grid.Row>
					<Grid.Column>
						<Image 
							src={recipImage} 
							centered
							as='a'
							href='#'
							onClick={() => setOpenConfirm(true)}
						/>
						Run Reciprocation Charges
					</Grid.Column>
					<Grid.Column>
						<Image size='large'
								src={resetImage} 
								centered
								as='a'
								href='#'
								onClick={() => setOpenRecipConfirm(true)}
						/>
						Run Reset Reciprocations
					</Grid.Column>
					<Grid.Column>

					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						
					</Grid.Column>
					<Grid.Column>

					</Grid.Column>
					<Grid.Column>

					</Grid.Column>
				</Grid.Row>
			</Grid>
			{(running) && 
				<Dimmer active inverted>
					<Loader active content='Running' inverted size='massive'/>
				</Dimmer>
			}
			<Confirm 
				open={openConfirm}
				onCancel={() => setOpenConfirm(false)}
				onConfirm={runReciprocations}
				content='Are you sure you want to run reciprocation charges? This should be run only once after the campaign is closed.'
			/>
			<Confirm 
				open={openRecipConfirm}
				onCancel={() => setOpenRecipConfirm(false)}
				onConfirm={runSetReciprocations}
				content='Are you sure you want to reset all reciprocations to false? This should be run only once before the campaign is opened.'
			/>
			<Modal
				size={"mini"}
				open={openMessageModal}
				onClose={() => setOpenMessageModal(false)}
				onOpen={() => setOpenMessageModal(true)}
			>
				<Modal.Header>Download report</Modal.Header>
				<Modal.Content>
				<Modal.Description>
					Reciprocation report successfully generated!
				</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
				<Button basic color={"red"} onClick={() => setOpenMessageModal(false)}>Cancel</Button>
				<CSVLink data={recipData} filename="reciprocation_report.csv">
					<Button style={{marginLeft: '15px'}} basic color={"green"}
							onClick={() => setOpenMessageModal(false)}>Download</Button>
				</CSVLink>
				</Modal.Actions>
			</Modal>
		</div>
	);
};

export default Tools;
