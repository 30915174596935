import React from 'react';
import { Modal } from 'semantic-ui-react';
import './ErrorModal.css';

const EZErrorModal = ({
  size = 'mini',
  open,
  setOpen,
  headerText,
  children,
}) => {
  return (
    <Modal
      size={size}
      dimmer={'blurring'}
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
    >
      <Modal.Header className="error">{headerText}</Modal.Header>
      <Modal.Content>
        <div style={{ fontSize: '14px' }}>{children}</div>
      </Modal.Content>
    </Modal>
  );
};

export default EZErrorModal;
