import React, {useState} from 'react';
import ReactSwitch from 'react-switch';
import {Button, Checkbox, Form, TextArea} from 'semantic-ui-react';
import {createCustomerAdmin} from '../../services/customerService';
import EZInput from '../EZInput/EZInput';
import PlacesAutocomplete from '../PlacesAutocomplete';
import TitlesCombo from '../TitlesCombo/TitlesCombo';
import './NewCustomerForm.css';
import error_img from '../../images/status_error.svg';
import {useHistory} from 'react-router-dom';
import EZErrorModal from '../ErrorModal/ErrorModal';
import cn from 'classnames';
import StaffCategoriesCombo from '../StaffCategoriesCombo/StaffCategoriesCombo';
import GreetingSuffix from "../GreetingSuffix/GreetingSuffix";

const Switcheroo = ({flag, handleChange, label = ''}) => (
  <div>
    <label>
      <ReactSwitch
        width={50}
        height={25}
        onChange={handleChange}
        checked={flag}
      />
      <span className="switch-label">{label}</span>
    </label>
  </div>
);

const clean = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
  return obj;
};

const NewCustomerForm = () => {
  const history = useHistory();
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [customerCreationError, setCustomerCreationError] = useState(false);
  const [titleId, setTitleId] = useState(1);
  const [firstNames, setFirstNames] = useState('');
  const [lastName, setLastName] = useState('');

  // Mispelled on purpose
  const [addres, setAddres] = useState('');
  const [defaultGreeting, setDefaultGreeting] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [googlePlaceId, setGooglePlaceId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [internalComment, setInternalComment] = useState('');
  const [reciprocityFlag, setReciprocityFlag] = useState(false);
  const [adminFlag, setAdminFlag] = useState(false);
  const [disabledFlag, setDisabledFlag] = useState(false);
  const [staffFlag, setStaffFlag] = useState(false);
  const [staffName, setStaffName] = useState('');
  const [staffCategoryIds, setStaffCategoryIds] = useState([]);
  const [staffPosition, setStaffPosition] = useState('');
  const [internalFlag, setInternalFlag] = useState(false);
  const [cardOnlyFlag, setCardOnlyFlag] = useState(false);
  const [emailOnlyFlag, setEmailOnlyFlag] = useState(false);
  const [paperOrderFlag, setPaperOrderFlag] = useState(false);
  const [basketWithoutOrdersFlag, setBasketWithoutOrdersFlag] = useState(false);
  const [checkCustomerFlag, setCheckCustomerFlag] = useState(false);
  const [greetingSuffix, setGreetingSuffix] = useState(0)
  const [titleText, setTitleText] = useState("Mr. & Mrs.")

  const [errors, setErrors] = useState({});
  const [createInProgress, setCreateInProgress] = useState(false);

  const createCustomer = () => {
    (async () => {
      setCreateInProgress(true);
      const {errors} = await createCustomerAdmin(
        clean({
          firstNames,
          lastName,
          email,
          titleId,
          defaultGreeting,
          fullAddress: addres,
          phone: phoneNumber,
          internalComment: internalComment,
          reciprocation: reciprocityFlag,
          cardOnly: cardOnlyFlag,
          emailOnly: emailOnlyFlag,
          basketWithoutOrders: basketWithoutOrdersFlag,
          checkCustomer: checkCustomerFlag,
          paperOrder: paperOrderFlag,
          disabled: disabledFlag,
          admin: adminFlag,
          internal: internalFlag,
          staff: staffFlag,
          staffName,
          staffPosition,
          addressLine2,
          greetingSuffix
        })
      );
      if (errors) {
        console.log('Something went wrong creating customer...', errors);
        setTimeout(() => {
          setCreateInProgress(false);
          setCustomerCreationError(errors.message);
          setOpenCreateModal(true);
        }, 300);
        return;
      }
      setTimeout(() => {
        setCreateInProgress(false);
        history.push('/search');
      }, 300);
    })();
  };

  const validateFields = () => {
    const errors = {};

    Object.entries({
      firstNames,
      lastName,
      addres,
      defaultGreeting,
    }).forEach(([key, value = '']) => {
      if (value.trim().length === 0) {
        errors[key] = 'Required field';
      }
    });

    setErrors(errors);
    return errors;
  };

  const handleChangeFirstNames = (e) => {
    setFirstNames(e.target.value);

    const {firstNames, ...otherErrors} = errors;
    if (e.target.value.trim().length === 0) {
      setErrors({...otherErrors, firstNames: 'Required Field'});
    } else {
      setErrors({...otherErrors});
    }
  };

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);

    const {lastName, ...otherErrors} = errors;
    if (e.target.value.trim().length === 0) {
      setErrors({...otherErrors, lastName: 'Required Field'});
    } else {
      setErrors({...otherErrors});
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeStaffName = (e) => {
    if (staffFlag) {
      setStaffName(e.target.value);
    }
  };
  const handleChangeStaffPosition = (e) => {
    if (staffFlag) {
      setStaffPosition(e.target.value);
    }
  };

  const handleChangeStaffCategories = (categories) => {
    const newCategoryIds = categories.map(({value}) => value);
    if (staffFlag) {
      setStaffCategoryIds(newCategoryIds);
    }
  };

  const handleChangeDefaultGreeting = (e) => {
    setDefaultGreeting(e.target.value);

    const {defaultGreeting, ...otherErrors} = errors;
    if (e.target.value.trim().length === 0) {
      setErrors({...otherErrors, defaultGreeting: 'Required Field'});
    } else {
      setErrors({...otherErrors});
    }
  };

  const handleChangeTitleId = ({title, titleId}) => {
    setTitleId(titleId);
    setTitleText(title)
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleChangeInternalComment = (e) => {
    setInternalComment(e.target.value);
  };

  const handleChangeAddressLine2 = (e) => {
    setAddressLine2(e.target.value);
  };

  const handleChangeReciprocity = () => {
    setReciprocityFlag(!reciprocityFlag);
  };

  const handleChangeCardOnly = () => {
    setCardOnlyFlag(!cardOnlyFlag);
  };

  const handleChangeEmailOnly = () => {
    setEmailOnlyFlag(!emailOnlyFlag);
  };

  const handleChangeBasketWithoutOrders = () => {
    setBasketWithoutOrdersFlag(!basketWithoutOrdersFlag);
  };

  const handleChangeCheckCustomer = () => {
    setCheckCustomerFlag(!checkCustomerFlag);
  };

  const handleChangePaperOrder = () => {
    setPaperOrderFlag(!paperOrderFlag);
  };

  const handleChangeDisabled = () => {
    setDisabledFlag(!disabledFlag);
  };
  const handleChangeAdmin = () => {
    setAdminFlag(!adminFlag);
  };
  const handleChangeStaff = (checked) => {
    setStaffFlag(checked);

    if (!checked) {
      setStaffName('');
      setStaffPosition('');
      setStaffCategoryIds([]);
    }
  };

  const handleChangeInternal = () => {
    setInternalFlag(!internalFlag);
  };

  const handleChangeAddress = ({address = '', googlePlaceId}) => {
    setAddres(address);

    const {addres, ...otherErrors} = errors;
    if (address.trim().length === 0) {
      setErrors({...otherErrors, addres: 'Required Field'});
    } else {
      setErrors({...otherErrors});
    }
  };

  return (
    <div className="review-info-content-boxx">
      <EZErrorModal
        headerText={'Validation Failed'}
        open={openValidationModal}
        setOpen={setOpenValidationModal}
      >
        <p> Some fields contain invalid information. </p>
        <p> Please, scroll up, fix the issues and try saving again. </p>
      </EZErrorModal>

      <EZErrorModal
        headerText={'Customer Creation Error'}
        open={openCreateModal}
        setOpen={setOpenCreateModal}
      >
        {customerCreationError}
      </EZErrorModal>

      <Form
        onSubmit={async (e) => {
          e.preventDefault();
        }}
      >
        <div className="two-column-container">
          <div className="heading">Contact Information</div>
        </div>

        <div className="two-column-container">
          <EZInput
            optional
            className="email-field"
            label="Email"
            labelClassName="code"
            name="email"
            value={email}
            handleValueChange={handleChangeEmail}
          />

          <TitlesCombo
            handleTitleIdChange={handleChangeTitleId}
            initialTitleId={titleId}
          />
        </div>
        <div className="two-column-container">
          <EZInput
            errors={errors}
            className="first-names-field"
            label="First Name(s)"
            labelClassName="code"
            name="firstNames"
            value={firstNames}
            handleValueChange={handleChangeFirstNames}
          />

          <EZInput
            errors={errors}
            className="last-name-field"
            label="Last Name"
            labelClassName="code"
            name="lastName"
            value={lastName}
            handleValueChange={handleChangeLastName}
          />
        </div>
        <div className="two-column-container">
          <PlacesAutocomplete
            errors={errors}
            onAddressChange={handleChangeAddress}
          />
        </div>
        <div className="two-column-container">
          <EZInput
            className="address-line2-field"
            optional
            label="Apartment or Suite"
            labelClassName="code"
            name="addressLine2"
            value={addressLine2}
            handleValueChange={handleChangeAddressLine2}
            inputProps={{placeholder: 'Apartment/Suite'}}
          />
          <EZInput
            className="phone-number-field"
            label="Phone Number"
            labelClassName="code"
            name="phoneNumber"
            value={phoneNumber}
            optional
            handleValueChange={handleChangePhoneNumber}
          />
        </div>
        <div className="two-column-container">
          <hr className="section-separator"/>
        </div>
        <div className="two-column-container-checkboxes">
          <div>
            <div className="heading">Order Settings</div>
            <div className="checkboxes-container">
              <Checkbox
                className="ez-checkbox"
                label="Reciprocity"
                onClick={handleChangeReciprocity}
                checked={reciprocityFlag}
              />
              <Checkbox
                className="ez-checkbox"
                label="Card Only"
                checked={cardOnlyFlag}
                onClick={handleChangeCardOnly}
              />
              <Checkbox
                className="ez-checkbox"
                label="Email Only"
                checked={emailOnlyFlag}
                onClick={handleChangeEmailOnly}
              />
              <Checkbox
                className="ez-checkbox"
                label="Basket Without Orders"
                checked={basketWithoutOrdersFlag}
                onClick={handleChangeBasketWithoutOrders}
              />
              <Checkbox
                className="ez-checkbox"
                label="Paper Order"
                checked={paperOrderFlag}
                onClick={handleChangePaperOrder}
              />
              <Checkbox
                className="ez-checkbox"
                label="Pay By Check"
                checked={checkCustomerFlag}
                onClick={handleChangeCheckCustomer}
              />
            </div>
          </div>
          <div>
            <div className="heading">Account Settings</div>
            <div className="checkboxes-container">
              <Checkbox
                className="ez-checkbox"
                label="Disabled"
                checked={disabledFlag}
                onClick={handleChangeDisabled}
              />
              <Checkbox
                className="ez-checkbox"
                label="Internal"
                checked={internalFlag}
                onClick={handleChangeInternal}
              />
              <Checkbox
                className="ez-checkbox"
                label="Admin"
                checked={adminFlag}
                onClick={handleChangeAdmin}
              />
            </div>
          </div>
        </div>

        <div className="two-column-container default-greeting-container">
          <div className="default-greeting-label">Default Greeting</div>
          <TextArea
            placeholder={`A freilechen Purim`}
            className={cn('default-greeting-text', {
              error: errors['defaultGreeting'],
            })}
            value={defaultGreeting}
            onChange={handleChangeDefaultGreeting}
          />

          <GreetingSuffix greetingSuffix={greetingSuffix}
                          setGreetingSuffix={setGreetingSuffix}
                          firstNames={firstNames}
                          lastName={lastName}
                          titleText={titleText}/>


          {errors['defaultGreeting'] && (
            <div className="error-message-div" style={{marginTop: '-10px'}}>
              <img src={error_img} alt="error-message-icon" height="20px"/>
              <span className="error-message">{errors['defaultGreeting']}</span>
            </div>
          )}
        </div>
        <div className="two-column-container default-greeting-container">
          <div className="default-greeting-label">Internal Comment</div>
          <TextArea
            className={cn('default-greeting-text', {
            })}
            value={internalComment}
            onChange={handleChangeInternalComment}
          />
        </div>
        <div className="two-column-container">
          <hr className="section-separator"/>
        </div>

        <div className="two-column-container staff-container">
          <div className="heading">Staff</div>
          <Switcheroo flag={staffFlag} handleChange={handleChangeStaff}/>
        </div>

        {staffFlag && (
          <>
            <div className="two-column-container">
              <EZInput
                optional
                className="staff-name-field"
                label="Staff Name"
                labelClassName="code"
                name="staffName"
                value={staffName}
                handleValueChange={handleChangeStaffName}
              />
              <EZInput
                optional
                className="staff-position-field"
                label="Staff Position"
                labelClassName="code"
                name="staffPosition"
                value={staffPosition}
                handleValueChange={handleChangeStaffPosition}
              />
            </div>
            <div className="two-column-container">
              <StaffCategoriesCombo
                handleChangeStaffCategories={handleChangeStaffCategories}
              />
            </div>
          </>
        )}

        <div className="two-column-container">
          <hr className="section-separator"/>
        </div>

        <div className="action-btn-container-new">
          <Button
            primary
            className="create-customer-btn"
            onClick={() => {
              const errors = validateFields();
              console.log({errors});
              if (Object.keys(errors).length === 0) {
                createCustomer();
              } else {
                setOpenValidationModal(true);
              }
            }}
            loading={createInProgress}
          >
            Create Customer
          </Button>
          <Button
            className="cancel-customer-btn"
            basic
            color="red"
            onClick={() => {
              history.push('/search');
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default NewCustomerForm;
