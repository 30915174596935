import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { BACKEND_URL, JWT_KEY } from '../../config';
import { getWithExpiry } from '../../services/localStorageService';
import './StaffCategoriesCombo.css';

const StaffCategoriesCombo = ({
  initialStaffCategoryIds = [],
  handleChangeStaffCategories = () => {},
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchStaffCategories = async () => {
      const response = await fetch(`${BACKEND_URL}api/v1/staffCategory`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
          'Accept-Encoding': 'gzip',
        },
      });
      let data = await response.json();
      data = data.map(({ category, staffCategoryId }) => ({
        key: staffCategoryId,
        text: category,
        value: staffCategoryId,
      }));
      setData(data);
    };
    fetchStaffCategories();
  }, []);

  const handleChange = (e, { value: staffCategoryIds }) => {
    const newCategories = data.filter(({ value }) =>
      staffCategoryIds.includes(value)
    );
    console.log({ newCategories });
    handleChangeStaffCategories(newCategories);
  };

  return (
    <div>
      <label className="code">Staff Categories</label>
      <Dropdown
        onChange={handleChange}
        className="staff-categories"
        placeholder="Select Staff Categories"
        fluid
        search
        selection
        multiple
        options={data}
        defaultValue={initialStaffCategoryIds}
      />
    </div>
  );
};

export default StaffCategoriesCombo;
