import React, {useEffect, useState} from 'react';
import {BACKEND_URL, FRONTEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {Button, Form, Modal} from "semantic-ui-react";
import {Editor} from "@tinymce/tinymce-react";
import SendEmail from "../SendEmail/SendEmail";

const EditTemplate = ({item, handlerDelete, ChangeTemplate, selectTemplate}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false)
  const [error, setError] = useState("")
  const [openErrorMassage, setOpenErrorMassage] = useState(false)
  const [deleteFlag, setDeleteFlag] = useState(false)
  const [value, setValue] = useState('');
  const [text, setText] = useState(null)
  const [template, setTemplate] = useState({})
  const [description, setDescription] = useState('');

  const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  useEffect(() => {
    setName(item.templateName)
    setIsLoading(true);
    fetch(`${BACKEND_URL}api/v1/templates/${item.templateName}
`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setDescription(result.template.description)
        setTemplate(result.template)
        setIsLoading(false)
      })
      .catch((error) => console.log('No way! ', error));
  }, [])

  useEffect(() => {
    setName(item.templateName)
  }, [])

  const updateTemplate = async (item,) => {
    setIsLoading(true)
    const response = await fetch(
      `${BACKEND_URL}api/v1/templates`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
        body: JSON.stringify(item),
      }
    ).catch((err) => {
      console.error(`Error updateCitiesAdmin:`, err);
      throw new Error(err);

    });
    if (!response.ok) {
      setIsLoading(false)
      const errors = await response.json();
      setError(`Error status:${errors.status}`)
      setOpenErrorMassage(true)
      console.log('ERROR in response (updateCitiesAdmin): ', errors);
      return {errors};
    }
    setTimeout(() => {
      setIsLoading(false);
      setOpen(true);
    }, 2500);

    const data = await response.json();
    console.log('RESULT: ', data);
    return data;
  };
  useEffect(() => {
    setTemplate(template)
  }, [template])


  const deleteTemplate = async (template_name) => {
    const response = await fetch(
      `${BACKEND_URL}api/v1/templates/${template_name}`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      }
    ).catch((err) => {
      console.error(`Error updatePekalach:`, err);
      return {errors: err};
    });
    if (!response.ok) {
      const errors = await response.json();
      setError(`Error status: ${errors.status}`)
      setOpenErrorMassage(true)
      console.log('ERROR in response (updatePekalach): ', errors);
      return {errors};
    }
    handlerDelete(template_name)
    const data = await response.json();
    console.log('RESULT: ', data);
    return data;

  };

  const handlerSaveTemplate = () => {
    const item = {
      name: name,
      description: text,
      template: value,

    }
    updateTemplate(item,)
  };


  return (
    <div>
      <h3>{item.templateName}</h3>
      <h5>{description}</h5>
      <Form style={{padding: "0"}}
            className="form-template">
        {isLoading
          ? <h3 style={{margin: "50px", textAlign: "center"}}>Loading...</h3>
          : <>
            <div style={{marginBottom: " 35px"}}>
            </div>
            <Editor
              apiKey='hec6cb4vrglyd7tgzoocbfnb3z0f8l2ibga05b1g2szqrbem'
              initialValue={template.version && template.version.template}
              value={value}
              onInit={(evt, editor) => {
                setText(editor.getContent({format: 'text'}));
              }}
              onEditorChange={(newValue, editor) => {
                setValue(newValue);
                setText(editor.getContent({format: 'text'}));
              }}
              init={{
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help custom',
                template_selected_content_classes: 'selcontent selectedcontent',
                menu: {
                  custom: {
                    title: 'Variables',
                    items: 'title fullName firstNames lastName loginLink loginButton unsubscribe'
                  }
                },
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                link_list: [
                  {title: 'My page 1', value: 'https://www.tiny.cloud'},
                  {title: 'My page 2', value: 'http://www.moxiecode.com'}
                ],
                image_list: [
                  {title: 'My page 1', value: 'https://www.tiny.cloud'},
                  {title: 'My page 2', value: 'http://www.moxiecode.com'}
                ],
                image_class_list: [
                  {title: 'None', value: ''},
                  {title: 'Some class', value: 'class-name'}
                ],
                importcss_append: true,
                file_picker_callback: function (callback, value, meta) {
                  /* Provide file and text for the link dialog */
                  if (meta.filetype === 'file') {
                    callback('https://www.google.com/logos/google.jpg', {text: 'My text'});
                  }

                  /* Provide image and alt text for the image dialog */
                  if (meta.filetype === 'image') {
                    callback('https://www.google.com/logos/google.jpg', {alt: 'My alt text'});
                  }

                  /* Provide alternative source and posted for the media dialog */
                  if (meta.filetype === 'media') {
                    callback('movie.mp4', {source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg'});
                  }
                },
                templates: [
                  {
                    title: `${template.name}`,
                    description: `${template.description}`,
                    content: template.version && template.version.template
                  },
                ],
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 500,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                skin: useDarkMode ? 'oxide-dark' : 'oxide',
                content_css: useDarkMode ? 'dark' : 'default',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                setup: function (editor) {
                  const toggleState = false;

                  editor.ui.registry.addMenuItem('title', {
                    text: 'Title',
                    onAction: function () {
                      editor.insertContent('%recipient.title%');
                    }
                  });

                  editor.ui.registry.addMenuItem('fullName', {
                    text: 'Full Name',
                    onAction: function () {
                      editor.insertContent('%recipient.fullName%');
                    }
                  });

                  editor.ui.registry.addMenuItem('firstNames', {
                    text: 'First Names',
                    onAction: function () {
                      editor.insertContent('%recipient.firstNames%');
                    }
                  });

                  editor.ui.registry.addMenuItem('lastName', {
                    text: 'Last Name',
                    onAction: function () {
                      editor.insertContent('%recipient.lastName%');
                    }
                  });
                  editor.ui.registry.addMenuItem('loginLink', {
                    text: 'Login Link',
                    onAction: function () {
                      editor.insertContent('<a href=' + FRONTEND_URL + '/login?uuid=%recipient.uuid%>login link</a>');
                    }
                  });
                  editor.ui.registry.addMenuItem('loginButton', {
                    text: 'Login Button',
                    onAction: function () {
                      editor.insertContent('<a href=' + FRONTEND_URL + '/login?uuid=%recipient.uuid% class="btn-primary" style="font-family: Helvetica Neue,Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Login Button</a>');
                    }
                  });

                  editor.ui.registry.addMenuItem('unsubscribe', {
                    text: 'Unsubscribe',
                    onAction: function () {
                      editor.insertContent('<a href="%mailing_list_unsubscribe_url%">Unsubscribe</a>');
                    }
                  });

                },
              }}
            /></>}
        <div className={"button-create"}>
          <Button
            onClick={() => handlerSaveTemplate()}
            primary
            loading={isLoading}
          >
            Save
          </Button>
          <Button
            disabled={true}
            onClick={() => setDeleteFlag(true)}
            color={"red"}
          >
            Delete
          </Button>
        </div>
      </Form>

      <Modal
        size={"mini"}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>Save Success!</Modal.Header>
        <Modal.Actions>
          <Button positive onClick={() => {
            ChangeTemplate(item);
            setOpen(false)
          }}>OK</Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={"mini"}
        open={deleteFlag}
        onClose={() => setDeleteFlag(false)}
        onOpen={() => setDeleteFlag(true)}
      >
        <Modal.Header>Delete Pekalach</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Are you sure you want to delete template: {item.templateName} ?
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic negative onClick={() => setDeleteFlag(false)}>Cancel</Button>
          <Button basic color={"green"} onClick={() => {
            deleteTemplate(item.templateName);
            setDeleteFlag(false)
          }}>Yes</Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={"mini"}
        open={openErrorMassage}
        onClose={() => setOpenErrorMassage(false)}
        onOpen={() => setOpenErrorMassage(true)}
      >
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenErrorMassage(false)}>OK</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default EditTemplate;