import React from 'react';
import {Grid} from 'semantic-ui-react'
import VerticalBar from "./Revenue/VerticalBar";
import PieChart from "./Revenue/PieChart";
import LineChart from "./Revenue/LineChart";
import UserLineChart from "./CustomersAndUsers/UserLineChart";
import UserPieChart from "./CustomersAndUsers/UserPieChart";
import OrdersGivenVerticalBar from "./Orders/OrdersGivenVerticalBar";
import OrdersCardVerticalBar from "./Orders/OrdersCardVerticalBar";
import CustomersReceiving from "./CustomersAndUsers/CustomersReceiving";


const MainScreen = () => {
	return (
		<>
			<h1>Revenue</h1>
			<Grid>
				<Grid.Row stretched columns={2}>
					<Grid.Column>
						<VerticalBar/>
					</Grid.Column>
					<Grid.Column>
						<PieChart/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1}>
					<Grid.Column>
						<LineChart/>
					</Grid.Column>
				</Grid.Row>
				<h1>Customers and Users</h1>
				<Grid.Row columns={3}>
					<Grid.Column>
						<UserLineChart/>
					</Grid.Column>
					<Grid.Column>
						<UserPieChart/>
					</Grid.Column>
					<Grid.Column>
						<CustomersReceiving/>
					</Grid.Column>
				</Grid.Row>
				<h1>Orders</h1>
				<Grid.Row columns={2}>
					<Grid.Column>
						<OrdersGivenVerticalBar/>
					</Grid.Column>
					<Grid.Column>
						<OrdersCardVerticalBar/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	)
};

export default MainScreen;
