import React, { createContext, useState } from 'react';
import { JWT_KEY } from '../config';
import { getWithExpiry } from '../services/localStorageService';

export const AuthenticationContext = createContext();

const AuthenticationProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(
    Boolean(getWithExpiry(JWT_KEY))
  );
  const value = { authenticated, setAuthenticated };

  return (
    <AuthenticationContext.Provider value={{ ...value }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
