import React, { useEffect, useState } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import SearchScreen from './components/SearchScreen';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { setWithExpiry } from './services/localStorageService';
import {Message} from 'semantic-ui-react';
import 'fontsource-lato';
import AuthenticationProvider from './contexts/AuthenticationProvider';
import { uuidLogin } from './services/loginService';
import EditCustomerProvider from './contexts/EditCustomerProvider';
import CustomersProvider from './contexts/CustomersProvider';
import NewCustomerScreen from './components/NewCustomerScreen/NewCustomerScreen';
import { BACKEND_URL, JWT_KEY, YEAR_KEY } from './config';
import PageLayout from './components/PageLayout/PageLayout';
import MergeScreen from './components/MergeScreen/MergeScreen';
import NewCustomersProvider from './contexts/NewCustomerProvider';
import MinimalCustomersProvider from './contexts/MinimalCustomersProvider';
import Cities from "./components/Cities/Cities";
import Settings from "./components/Settings/Settings";
import MainScreen from "./components/MainScreen/MainScreen";
import EditPekalach from "./components/EditPekalach/EditPekalach";
import NewPekalachScreen from "./components/NewPekalachScreen/NewPekalachScreen";
import Report from "./components/Report/Report";
import EZInput from "./components/EZInput/EZInput";
import PacmanLoader from "react-spinners/PacmanLoader";
import { useJwtToken } from './hooks/useJwtToken';
import {ToastContainer} from 'react-toastify'
import Tools from "./components/Tools/Tools"
import Invoices from './components/Invoices/Invoices';
import TableOfOrders from "./components/TableOfOrders/TableOfOrders";
import Email from "./components/Email/Email";
import NewTemplate from "./components/NewTemplate/NewTemplate";


export default function App() {
  return (
    <Router>
      <AuthenticationProvider>
        <Main />
      </AuthenticationProvider>
    </Router>
  );
}

const SilentLogin = ({ uuid }) => {
  let history = useHistory();
  const { isJwtValid } = useJwtToken();

  useEffect(() => {
    const login = async () => {
      const loginStatus = await uuidLogin({ uuid });
      if (loginStatus) {
        history.push('/search');
        return;
      }

      history.push('/login');
    };
    login();
  });

  if (isJwtValid()) {
    return <Redirect to={'/main'} />;
  }

  return <div>Loading...</div>;
};

const Main = () => {
	let location = useLocation();

	return (
		<>
			<ToastContainer />
			<Switch location={location}>
				<Route
					exact
					path="/"
					render={(props) => {
						const {search} = props.location;
						const queryParams = new URLSearchParams(search);
						const uuid = queryParams.get('uuid');
						if (uuid) {
							return <SilentLogin uuid={uuid}/>;
						}

						return <LoginPage/>;
					}}
				/>
				<Route path="/login">
					<LoginPage/>
				</Route>
				<PageLayout>
					<PrivateRoute path="/main">
						<MainScreen/>
					</PrivateRoute>
					<PrivateRoute path="/pekalach">
						<EditPekalach/>
					</PrivateRoute>
					<PrivateRoute path="/setting">
						<Settings/>
					</PrivateRoute>
					<PrivateRoute path="/invoices">
						<Invoices/>
					</PrivateRoute>
					<PrivateRoute path="/report">
						<Report/>
					</PrivateRoute>
					<PrivateRoute path="/new-email">
						<Email/>
					</PrivateRoute>
					<PrivateRoute path="/cities">
						<Cities/>
					</PrivateRoute>
					<PrivateRoute path="/tools">
						<Tools/>
					</PrivateRoute>
					<PrivateRoute path="/search">
						<CustomersProvider>
							<EditCustomerProvider>
								<SearchScreen/>
							</EditCustomerProvider>
						</CustomersProvider>
					</PrivateRoute>
					<PrivateRoute path="/orders">
						<TableOfOrders/>
					</PrivateRoute>
					<PrivateRoute path="/merge">
						<NewCustomersProvider>
							<MinimalCustomersProvider>
								<EditCustomerProvider>
									<MergeScreen/>
								</EditCustomerProvider>
							</MinimalCustomersProvider>
						</NewCustomersProvider>
					</PrivateRoute>
					<PrivateRoute path="/new-customer">
						<NewCustomerScreen/>
					</PrivateRoute>
					<PrivateRoute path="/new-pekalach">
						<NewPekalachScreen/>
					</PrivateRoute>
					<PrivateRoute path="/new-template">
						<NewTemplate/>
					</PrivateRoute>
				</PageLayout>
			</Switch>
		</>
	);
};

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const { isJwtValid } = useJwtToken();

  if (!isJwtValid()) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  return <Route {...rest} render={({ location }) => children} />;
}

function LoginPage() {
  let history = useHistory();
  let location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [verifying, setVerifying] = useState(false);
	const [pacmanColor, setPacmanColor] = useState('#e5bb34');
  const { isJwtValid } = useJwtToken();
  let { from } = location.state || { from: { pathname: '/' } };

	const login = async (e) => {
		e.preventDefault();
		setVerifying(true);
    setError('');
    const response = await fetch(`${BACKEND_URL}api/v1/auth/login`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }), // body data type must match "Content-Type" header
    }).catch((err) => {
      setVerifying(false);
      console.error('Error:', err);
      setError(err);
    });

    if (!response) {
      setVerifying(false);
      return;
    }

    const {
      accessToken,
      userId,
      expiresIn,
      activeYear,
      error: loginError,
      errors,
      message: loginErrorMessage,
    } = await response.json();

    if (loginError || errors) {
      let errorMsg = errors ? errors.join(', ') : '';
      if (loginError) {
        errorMsg = `${loginError}: ${loginErrorMessage}`;
      }
      errorMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
      setError(`${errorMsg}`);
      setVerifying(false);
      return;
    }

    setWithExpiry(JWT_KEY, accessToken, expiresIn);
    setWithExpiry(YEAR_KEY, activeYear, expiresIn);
    setVerifying(false);
    history.replace(from);
  };

  if (isJwtValid()) {
    return <Redirect to={'/main'} />;
  }

  return (
			<>
				<div className="login-page">
					<div className="login-form">
						<div className="sign-in-title">Login</div>
						<div className="sign-in-subtitle">
							Please enter your email address and password to proceed.
							{error && (
								<Message className="login-error" negative>
									<Message.Header>Error</Message.Header>
									<p>{error}</p>
								</Message>
							)}
						</div>

						<form onSubmit={login}>
							<div className="email-pass-container">
								<EZInput
									className="email-field"
									label="Email Address"
									labelClassName="code"
									name="email"
									value={email}
									handleValueChange={e=>setEmail(e.target.value)}
									inputProps={{ placeholder: 'name@email.com' }}
								/>
								<div className={"password-container"}>
								<EZInput
									className="password-field"
									type="password"
									label="Password"
									labelClassName="code"
									name="password"
									value={password}
									handleValueChange={(e) => setPassword(e.target.value)}
									inputProps={{ placeholder: 'Enter your password' }}
								/>
								</div>
							</div>
							<button
								onMouseEnter={() => setPacmanColor('#071631')}
								onMouseLeave={() => setPacmanColor('#e5bb34')}
								className="login-in-btn"
								type="submit"
								disabled={!(password && email)}
								onClick={(e)=>login(e)}

							>
								{verifying ? (
									<div
										style={{
											width: '30px',
											marginTop: '-10px',
											marginLeft: 'auto',
											marginRight: 'auto',
											padding: '10px 50px',
										}}
									>
										<PacmanLoader size={10} loading={true} color={pacmanColor} />
									</div>
								) : (
									<span>Login</span>
								)}
							</button>
						</form>
					</div>
				</div>


		</>
  );
}
