import {Pie} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {BACKEND_URL, JWT_KEY, YEAR_KEY} from "../../../config";
import {getWithExpiry} from "../../../services/localStorageService";


const UserPieChart = () => {
	
	const [userPie, setUserPie] = useState([]);
	useEffect(() => {
		fetch(`${BACKEND_URL}api/v1/reports/count/consolidated?year=${getWithExpiry(YEAR_KEY)}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setUserPie(result[0])
			})
			
			.catch((error) => console.log('No way! ', error));
	}, [])
	
	
	const current_year = userPie;
	const colors = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a']
	
	const data = {
		labels: ['Customers Giving', 'Customers Not Giving'],
		datasets: [
			{
				label: 'Customers Giving',
				data: [current_year.totalCustomersGiving, current_year.totalCustomers - current_year.totalCustomersGiving],
				backgroundColor: colors.slice(0, 3),
				minBarLength: 50
			}
		]
	};
	
	function getPercent(data, value) {
		let total = data.reduce((a, b) => a + b, 0);
		return (Math.round(value / total * 100).toFixed(2));
	}
	
	const config = {
		type: 'pie',
		data: data,
		options: {
			plugins: {
				title: {
					display: true,
					text: 'Customers Giving (Current Year)'
				},
				datalabels: {
					color: 'white',
					font: {
						weight: 'bold'
					},
					formatter: function (value, context) {
						return value + '\n' + getPercent(context.chart.data.datasets[0].data, value) + '%';
					}
				}
			},
			scales: {
				x: {
					title: {
						display: true,
						text: 'Total: ' + current_year.totalCustomers
					},
					stacked: true
				},
				y: {
					beginAtZero: true,
					
				},
			},
		},
	};
	
	
	return (
		<div>
			<Pie data={data} options={config.options}/>
		</div>
	)
};

export default UserPieChart;
