import React, {useEffect, useState} from 'react';
import {BACKEND_URL, JWT_KEY, YEAR_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {Pagination, Table, Icon, Checkbox, Select, Input, Modal, Form, Button, TextArea} from "semantic-ui-react";
import "./Invoices.css"
import _ from 'lodash'
import CustomerOrders from "../CustomerOrders/CustomerOrders";
import SearchFieldIcon from '../TableOfOrders/SearchFieldIcon';
import {CSVLink} from "react-csv";
import style from "./Invoices.css"

const Invoices = () => {
    const [invoices, setInvoices] = useState([]);
    const [year, setYear] = useState(0);
    const [originalInvoices, setOriginalInvoices] = useState([]);
    const [downloadCSV, setDownloadCSV] = useState([]);
    const [openMessageModal, setOpenMessageModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [invoicesPerPage] = useState(25);
    const [checksPaid, setChecksPaid] = useState([]); 
    const [sortColumn, setSortColumn] = useState("sortName");
    const [sortDirection, setSortDirection] = useState("ascending");
    const [show, setShow] = useState();
    const [selectYear, setSelectYear] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openOrderEditModal, setOpenOrderEditModal] = useState(false);

    const lastInvoicesIndex = currentPage * invoicesPerPage;
    const firstInvoicesIndex = lastInvoicesIndex - invoicesPerPage;
    const currentInvoices = invoices.slice(firstInvoicesIndex, lastInvoicesIndex);
    const totalInvoices = invoices.length;
    const pageNumbers = [];

    const [invoiceId, setInvoiceId] = useState();
    const [checkNumber, setCheckNumber] = useState();
    const [notes, setNotes] = useState('');

    for (let i = 1; i <= Math.ceil(totalInvoices / invoicesPerPage); i++) {
      pageNumbers.push(i)
    };
    const paginate = (pageNumber) => {
      setCurrentPage(pageNumber)
    };
  
    useEffect(() => {
      setYear(getWithExpiry(YEAR_KEY));
      setIsLoading(true)
      fetch(`${BACKEND_URL}api/v1/newinvoice/summary?year=${getWithExpiry(YEAR_KEY)}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setInvoices(_.sortBy(result, 'sortName'));
          setOriginalInvoices(_.sortBy(result, 'sortName'));
          const currentChecksPaid = checksPaid;
          result.map(r => {
              if(r.checkPaid) {
                currentChecksPaid.push(r.invoiceId);
              }
          })
          setChecksPaid(currentChecksPaid);
          setIsLoading(false);
        })
  
        .catch((error) => console.log('No way! ', error));

        fetch(`${BACKEND_URL}api/v1/orders/admin/years`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Encoding': 'gzip',
            Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setSelectYear(result.map((w) => ({key: w, value: w, text: w})))
          })
          .catch((error) => console.log('No way! ', error));
    }, [])

    const updateOrder = async () => {
      const response = await fetch(
        `${BACKEND_URL}api/v1/newinvoice/${invoiceId}`,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
          },
          body: JSON.stringify({
            checkNumber: checkNumber,
            notes: notes
          }),
        }
      ).catch((err) => {
        console.error(`Error updateOrder:`, err);
        return {errors: err};
      });
      
      if (!response.ok) {
        const errors = await response.json();
        console.log('ERROR in response (updateOrder): ', errors);
        return {errors};
      }
      
      const data = await response.json();
      setOpenOrderEditModal(false);
      changeYears(getWithExpiry(YEAR_KEY))
      console.log('RESULT: ', data);
      return {data};
    };

    const changeYears = (year) => {
      setIsLoading(true)
      fetch(`${BACKEND_URL}api/v1/newinvoice/summary?year=${year}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setInvoices(_.sortBy(result, 'sortName'));
          const currentChecksPaid = checksPaid;
          result.map(r => {
              if(r.checkPaid) {
                currentChecksPaid.push(r.invoiceId);
              }
          })
          setChecksPaid(currentChecksPaid);
          setIsLoading(false);
        })
    }
    
    const generatePaymentLink = (stripePaymentId) => {
      let link = "https://dashboard.stripe.com/payments/" + stripePaymentId;
      window.open(link, "_blank");
        // return <a href={"https://dashboard.stripe.com/payments/" + stripePaymentId} target="_blank">{stripePaymentId}</a>
    };

    const formatCurrency = (value) => {
        return parseFloat(value).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2});
    }

    const updateCheckPaid = async (invoice) => {
      const response = await fetch(
        `${BACKEND_URL}api/v1/newinvoice/${invoice.invoiceId}`,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
          },
          body: JSON.stringify({
                      invoiceId: invoice.invoiceId,
            checkPaid: invoice.checkPaid
          }),
        }
      ).catch((err) => {
        console.error(`Error update invoices:`, err);
        return {errors: err};
      });
      
      if (!response.ok) {
        const errors = await response.json();
        console.log('ERROR in response (update invoices): ', errors);
        return {errors};
      }
      
      const data = await response.json();
      return {data};
	};

    const toggleCheckPaid = (invoiceId) => {
        const currentChecksPaid = [...checksPaid];
        const find = currentChecksPaid.indexOf(invoiceId);
        var invoice = {"invoiceId": invoiceId}
        if(find > -1) {
            currentChecksPaid.splice(find, 1);
            invoice["checkPaid"] =false;
        } else {
            currentChecksPaid.push(invoiceId);
            invoice["checkPaid"] =true;
        }
        updateCheckPaid(invoice);
        setChecksPaid(currentChecksPaid);  
    }

    const handleClick = (id) => {
      if(show) {
        setShow(null);
      } else {
        setShow(id);
      }
    }

    const getAllInvoices = () => {
      setDownloadCSV(invoices);
      setOpenMessageModal(true);
    }

    const handleSort = (column) => {
      if(sortColumn === column) {
        setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
        setInvoices(invoices.slice().reverse());
      } else {
        setSortColumn(column);
        setSortDirection('ascending');
        setInvoices(_.sortBy(invoices, [column]));
      }
    }

    const handleSearch = (searchText) => {
      setSearchTerm(searchText);
      setInvoices(_.filter(originalInvoices, function(order){
        return order.name.toLowerCase().includes(searchText.toLowerCase()) || order.invoiceId.includes(searchText);
      }))
    }

    const yearSetSelect = {
      options: selectYear,
      selected: "",
    }

    const handleYearChange = (event, {value}) => {
      setYear(value);
      changeYears(value);
      setSortColumn("sortName");
      setSortDirection("ascending");
    }

    const handleCheckNumberChange = (e, {name, value}) => {
      setCheckNumber(value);
    }

    const handleNotesChange = (e, {name, value}) => {
      setNotes(value);
    }

    const handleRowClick = (invoiceId, checkNumber, notes) => {
      setNotes(notes);
      setCheckNumber(checkNumber);
      setInvoiceId(invoiceId)
      setOpenOrderEditModal(true)
    }

    return (
      <div className="content-table-one">
        <h1 className="title">Invoices</h1>
            <div className="select-group">
              <div>
                <span>Year: </span>
                <Select
                  defaultValue={getWithExpiry(YEAR_KEY)}
                  options={yearSetSelect.options}
                  placeholder='Select year'
                  onChange={handleYearChange}
                />
                <Input
                  icon={<Icon name="search" />}
                  className="search"
                  placeholder="Search name or invoice.."
                  value={searchTerm}
                  onChange={e => handleSearch(e.target.value)}
                >
                  <input />
                  <SearchFieldIcon
                    searchValue={searchTerm}
                    setSearchValue={setSearchTerm}
                    handleSearch={handleSearch}
                  />
                </Input>
              </div>
              <div className={style["select-right"]}>
                <Button
                  onClick={() => getAllInvoices()}
                  color='green'
                  >
                  <div className='button'>
                    <Icon size="large" name="file excel"/>
                    <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
                    Download
                    </span>
                  </div>
                </Button>
		  	      </div>
            </div>
            {isLoading ? <h3 style={{margin: "50px", textAlign: "center"}}>Loading...</h3>
            : (<>
            <Table color={"green"}  selectable compact sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign={"left"} 
                          sorted={sortColumn === 'sortName' ? sortDirection : null}
                          onClick={() => handleSort('sortName')}>Name</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'invoiceId' ? sortDirection : null}
                          onClick={() => handleSort('invoiceId')}>InvoiceId</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'orders' ? sortDirection : null}
                          onClick={() => handleSort('orders')}>Orders</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'items' ? sortDirection : null}
                          onClick={() => handleSort('items')}>Pekalach</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'paymentMethod' ? sortDirection : null}
                          onClick={() => handleSort('paymentMethod')}>Payment Method</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'checkPaid' ? sortDirection : null}
                          onClick={() => handleSort('checkPaid')}>Check Paid</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'checkNumber' ? sortDirection : null}
                          onClick={() => handleSort('checkNumber')}>Check #</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'createdAt' ? sortDirection : null}
                          onClick={() => handleSort('createdAt')}>Created</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'total' ? sortDirection : null}
                          onClick={() => handleSort('total')}>Total</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}
                          sorted={sortColumn === 'refund' ? sortDirection : null}
                          onClick={() => handleSort('refund')}>Refunded</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"left"}>Stripe</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentInvoices.map((key) => (
                    <><Table.Row key={key.invoiceId}
                        warning={key.paymentMethod == 'check' && !checksPaid.includes(key.invoiceId)}
                        // onClick={() => handleClick(key.invoiceId)}
                        verticalAlign='middle'
                        // className='tableRow'
                      >
                        <Table.Cell 
                          textAlign={"left"} 
                          onClick={() => handleClick(key.invoiceId)}
                          className='name-cell'
                        >

                          {key.name}
                        </Table.Cell>
                        <Table.Cell textAlign={"left"}>{key.invoiceId}</Table.Cell>
                        <Table.Cell textAlign={"left"}>{key.orders}</Table.Cell>
                        <Table.Cell textAlign={"left"}>{key.items}</Table.Cell>
                        <Table.Cell textAlign={"left"}>
                          {key.paymentMethod == 'check' ?
                            <div><Icon name='envelope outline' color='blue' /> Check</div> :
                            <div><Icon name='credit card outline' color='green' /> Credit</div>}
                        </Table.Cell>
                        <Table.Cell textAlign={"left"}>
                          {key.paymentMethod == 'check' ?
                            <div className='checkmark-slider'>
                              <Icon
                                name={checksPaid.includes(key.invoiceId) ? 'checkmark' : 'attention'}
                                size='large'
                                className={checksPaid.includes(key.invoiceId) ? 'check-paid-icon' : 'check-unpaid-icon'} />
                              <Checkbox slider defaultChecked={key.checkPaid} onChange={() => toggleCheckPaid(key.invoiceId)} />
                            </div>
                            : null}
                        </Table.Cell>
                        <Table.Cell textAlign={"left"}>   
                          <span className='name-cell' onClick={() => handleRowClick(key.invoiceId, key.checkNumber, key.notes)}>     
                            {key.checkNumber}
                          </span> 
                        </Table.Cell>
                        <Table.Cell textAlign={"left"}>{key.createdAt}</Table.Cell>
                        <Table.Cell textAlign={"left"}>{formatCurrency(key.total)}</Table.Cell>
                        <Table.Cell textAlign={"left"}>{formatCurrency(key.refundAmount)}</Table.Cell>
                        <Table.Cell textAlign={"left"}>{key.stripePaymentId && 
                          <Icon 
                            link 
                            name='stripe' 
                            size='big'
                            color='blue'
                            onClick={() => generatePaymentLink(key.stripePaymentId)} />}
                        </Table.Cell>
                      </Table.Row>
                      {show && show === key.invoiceId ? (
                      <Table.Row><Table.Cell colSpan='10'>   
                        <CustomerOrders year={year} invoiceId={key.invoiceId} customerId={key.customerId} showOrders={handleClick}/>
                      </Table.Cell></Table.Row>
                      ) : (null)
                      }
                  </>))}
                </Table.Body>
            </Table>
            
            <Modal
              open={openOrderEditModal}
              onClose={() => setOpenOrderEditModal(false)}
            >
            <Modal.Header>Edit Invoice</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <Form>
                    <Form.Group width='equal'>
                      <Form.Field>
                        <label>Invoice</label>
                        {invoiceId}
                      </Form.Field>
                      <Form.Field
                        control={Input}
                        label='Check Number'
                        defaultValue={checkNumber}
                        onChange={handleCheckNumberChange}
                        search
                      />
                      <Form.Field
                        control={TextArea}
                        label='Notes'
                        value={notes}
                        onChange={handleNotesChange}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button basic color={"red"} onClick={() => setOpenOrderEditModal(false)}>Cancel</Button>
                <Button basic color={"green"} onClick={() => updateOrder()}>Submit</Button>
              </Modal.Actions>
            </Modal>

            <div className="pagination-greetings">
              <Pagination
                boundaryRange={0}
                defaultActivePage={1}
                siblingRange={1}
                totalPages={pageNumbers.length}
                onPageChange={(event, data) => paginate(data.activePage)}
              />
            </div>
          </>)}
        <Modal
          size={"mini"}
          open={openMessageModal}
          onClose={() => setOpenMessageModal(false)}
          onOpen={() => setOpenMessageModal(true)}
        >
        <Modal.Header>Invoices Report</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Invoices report successfully generated!
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color={"red"} onClick={() => setOpenMessageModal(false)}>Cancel</Button>
            <CSVLink data={downloadCSV} filename="Invoices.csv">
              <Button style={{marginLeft: '15px'}} basic color={"green"}
                      onClick={() => setOpenMessageModal(false)}>Download</Button>
            </CSVLink>
          </Modal.Actions>
        </Modal>
      </div>
    );
};

export default Invoices;