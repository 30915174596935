import React, {useEffect, useState} from 'react';
import {Button, Message, Form, Modal} from 'semantic-ui-react'
import "../NewPekalachScreen/NewPekalachScreen.css"
import {BACKEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";

const EditPekalachForm = ({item, handlerDelete, ChangePekalach}) => {
  
  const [isLoading, setIsLoading] = useState(false)
  const [dataImg, setDataImg] = useState(null)
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState("");
  const [salePrice, setSalePrice] = useState(null);
  const [soldOut, setSoldOut] = useState(false);
  const [itemQuantity, setItemQuantity] = useState(0);
  const [quantitySold, setQuantitySold] = useState(0);
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false)
  const [error, setError] = useState("")
  const [openErrorMassage, setOpenErrorMassage] = useState(false)
  const [imageUrl, setImageUrl] = useState("")
  const [deleteFlag, setDeleteFlag] = useState(false)
  const [image, setImage] = useState(null)
  
  const colorSet = {
    options: [
      {value: 'red', text: 'Red'},
      {value: 'blue', text: 'Blue'},
      {value: 'green', text: 'Green'},
      {value: 'pink', text: 'Pink'},
      {value: 'orange', text: 'Orange'},
    ],
    selected: `${color}`, // <== Here, the values of selected options
  }
  
  
  useEffect(() => {
    setName(item.name)
    setColor(item.color)
    setPrice(item.price)
    setDescription(item.description)
    setSalePrice(item.salePrice)
    setSoldOut(item.soldOut)
    setItemQuantity(item.itemQuantity)
    setQuantitySold(item.quantitySold)
    setId(item.itemId)
    setImageUrl(item.imageUrl)
  }, [])
  
  const updatePekalach = async (item, id) => {
    setIsLoading(true)
    const response = await fetch(
      `${BACKEND_URL}api/v1/item/admin/${id}`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
        body: JSON.stringify(item),
      }
    ).catch((err) => {
      console.error(`Error updateCitiesAdmin:`, err);
      throw new Error(err);
      // return {errors: err};
    });
    if (!response.ok) {
      const errors = await response.json();
      setIsLoading()
      setError(`Error status:${errors.status}`)
      setOpenErrorMassage(true)
      console.log('ERROR in response (updateCitiesAdmin): ', errors);
      return {errors};
    }
    setTimeout(() => {
      setIsLoading(false);
      setOpen(true);
    }, 2500);
    
    const data = await response.json();
    console.log('RESULT: ', data);
    return data;
  };
  
  const updateImage = async (dataImg, id) => {
    const response = await fetch(
      `${BACKEND_URL}api/v1/item/admin/upload/${id}`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
        body: dataImg,
      }
    ).catch((err) => {
      console.error(`Error updateImage:`, err);
      return {errors: err};
    });
    
    if (!response.ok) {
      const errors = await response.json();
      setError(`Error status:${errors.status}`)
      setOpenErrorMassage(true)
      console.log('ERROR in response (updateImage): ', errors);
      return {errors};
    }
    setTimeout(() => {
      setIsLoading(false);
      setOpen(true);
    }, 2500);
  };
  
  const deletePekalach = async (id) => {
    const response = await fetch(
      `${BACKEND_URL}api/v1/item/admin/${id}`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      }
    ).catch((err) => {
      console.error(`Error updatePekalach:`, err);
      return {errors: err};
    });
    if (!response.ok) {
      const errors = await response.json();
      setError(`Error status: ${errors.status}`)
      setOpenErrorMassage(true)
      console.log('ERROR in response (updatePekalach): ', errors);
      return {errors};
    }
    handlerDelete(id)
    const data = await response.json();
    console.log('RESULT: ', data);
    return data;
    
  };
  
  const handlerImg = (e) => {
    const dataImg = new FormData()
    const files = e.target.files[0];
    setImage(`: ${files.name}`)
    dataImg.append("file", files)
    setDataImg(dataImg)
  }
  const handlerNamePekalach = (e) => {
    setName(e.target.value);
  }
  const handlerColorPekalach = (event, {value}) => {
    setColor(value);
  }
  const handlerPricePekalach = (e) => {
    setPrice(e.target.value);
  }
  const handlerDescriptionPekalach = (e) => {
    setDescription(e.target.value);
  }
  const handlerSalePricePekalach = (e) => {
    setSalePrice(e.target.value);
  }
  const quantityChangeHandler = (e) => {
    setItemQuantity(e.target.value);
  }

  const handlerSavePekalach = () => {
    const item = {
      color,
      description,
      imageUrl,
      name,
      price,
      salePrice,
      itemQuantity,
      soldOut,
    }
    updatePekalach(item, id).then((data) => {
      if (dataImg) {
        updateImage(dataImg, data.itemId)
      }
    });
  };
  
  return (
    <div className={"new-template-screen"}>
      <h2>Edit Pekalach</h2>
      <Form
        className="form-template">
        <div className="upload-file__wrapper">
          <Form.Input
            type="file"
            name="files[]"
            id="upload-file__input_1"
            className="upload-file__input"
            onChange={(e) => {
              handlerImg(e)
            }}
          
          />
          <label className="upload-file__label" htmlFor="upload-file__input_1">
            <span className="upload-file__text">Attach image {image} </span>
          </label>
        
        </div>
        <Form.Group widths='equal'>
          <Form.Input
            onChange={(e) => handlerNamePekalach(e)}
            label='Name'
            placeholder='Name'
            value={name}
          />
          <Form.Select placeholder={color} options={colorSet.options}
                       onChange={handlerColorPekalach}
                       label='Color'
                       value={color}
                       defaultValue={colorSet.selected}
          />
          <Form.Input
            onChange={(e) => handlerPricePekalach(e)}
            label='Price'
            placeholder='Price'
            value={price}
            type={"number"}
          />
        </Form.Group>
        <Form.TextArea
          onChange={(e) => handlerDescriptionPekalach(e)}
          label='Description'
          placeholder='Description'
          value={description}
        />
        <Form.Group widths='equal'>
          <Form.Input
            onChange={(e) => handlerSalePricePekalach(e)}
            label='Sale Price'
            placeholder='Sale Price'
            value={salePrice}
            type={"number"}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            onChange={(e) => quantityChangeHandler(e)}
            label='Quantity for Sale'
            placeholder='0'
            value={itemQuantity}
            type={"number"}
          />
          <Form.Input
            readOnly
            label='Sold'
            placeholder='0'
            value={quantitySold}
          />
          <Form.Input
            readOnly
            label='Quantity Left'
            placeholder='0'
            value={itemQuantity-quantitySold}
          />
        </Form.Group>
        {itemQuantity-quantitySold <= 0 && 
        <>
          <Message
            negative
            icon='exclamation circle'
            content='Sold Out'
          />
        </>
        }
        <div className={"button-create"}>
          <Button
            onClick={() => handlerSavePekalach()}
            primary
            loading={isLoading}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setDeleteFlag(true)
            }}
            basic
            color="red"
            className="ez-basic-btn"
          >
            Delete
          </Button>
        </div>
      </Form>
      <Modal
        size={"mini"}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>Save Success!</Modal.Header>
        <Modal.Actions>
          <Button positive onClick={() => {
            ChangePekalach(item);
            setOpen(false)
          }}>OK</Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={"mini"}
        open={deleteFlag}
        onClose={() => setDeleteFlag(false)}
        onOpen={() => setDeleteFlag(true)}
      >
        <Modal.Header>Delete Pekalach</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Are you sure you want to delete pekalach?
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic negative onClick={() => setDeleteFlag(false)}>Cancel</Button>
          <Button basic color={"green"} onClick={() => {
            deletePekalach(id);
            setDeleteFlag(false)
          }}>Yes</Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={"mini"}
        open={openErrorMassage}
        onClose={() => setOpenErrorMassage(false)}
        onOpen={() => setOpenErrorMassage(true)}
      >
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenErrorMassage(false)}>OK</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default EditPekalachForm;