import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {BACKEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {Button, Icon, Table, Divider, Header, Message} from "semantic-ui-react";
import EditTemplate from "../EditTemplate/EditTemplate";
import style from "./Email.module.css"
import SendEmail from "../SendEmail/SendEmail";


const Email = () => {

  const buttonLabel = (open) => (open ? 'Close' : 'View');
  const [templateName, setTemplateName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [saveChange, setSaveChange] = useState([]);
  const [selectTemplate, setSelectTemplate] = useState([]);
  const [tagStats, setTagStats] = useState([]);
  const history = useHistory();


  useEffect(() => {
    setIsLoading(true);
    fetch(`${BACKEND_URL}api/v1/templates`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setSelectTemplate(result.map((i) => ({
          text: i, value: i,
        })))
        setTemplateName(result.map((obj) => ({
          templateName: obj, isOpen: false
        })))
      })
      .catch((error) => console.log('No way! ', error));

      fetch(`${BACKEND_URL}api/v1/email/stats`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setTagStats(result
            .sort((a, b) => (a.tag < b.tag) ? 1 : ((b.tag < a.tag) ? -1 : 0))
            .map((o) => ({...o, id: randomId()})))
        })
        .catch((error) => console.log('No way! ', error));
        setIsLoading(false)
  }, [saveChange])

  function randomId() {
		return Math.random();
	};

  const handleChangeOpen = (id) => {
    const changeOpen = templateName.map(item => {
      if (id === item.templateName) {
        const edit = {
          ...item,
          isOpen: !item.isOpen
        };
        return edit;
      }
      return item
    })
    setTemplateName(changeOpen)
  };
  const ChangeTemplate = (item) => {
    setSaveChange(item);
  };

  const handlerDelete = (id) => {
    const filteredItem = templateName.filter((item) => item.templateName !== id);
    setTemplateName(filteredItem);
  };

  function percentageCalculator(total, amount) {       
    return Math.round((amount * 100) / total)
}

  return (
    <div className={style["content-template"]}>
      {isLoading
        ? <h3 style={{margin: "50px", textAlign: "center"}}>Loading...</h3>
        : (<>
          <Divider horizontal>
            <Header as='h4'>
              <Icon name='file outline' />
              Email Templates
            </Header>
          </Divider>
          <div>
            {/* <h2 style={{marginBottom: "27px"}}>Email Templates</h2> */}
            <Button
              onClick={() => history.push('/new-template')}
              basic
              color="blue"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon name="plus" size="large"/>
                <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
                Add New Template
              </span>
              </div>
            </Button>
          </div>
          {templateName.map((item, index) => (
            <Table key={index}>
              <Table.Body>
                <Table.Row>
                  {item.isOpen ?
                    <Table.Cell>
                      <EditTemplate selectTemplate={selectTemplate} ChangeTemplate={ChangeTemplate}
                                    handlerDelete={handlerDelete} item={item}/>
                    </Table.Cell>
                    : <><Table.Cell width={1}>
                      <h3>{item.templateName}</h3>
                    </Table.Cell>
                    </>}
                  <Table.Cell textAlign={"right"} width={1}>
                    <Button style={{marginRight: "30px"}} basic color="blue"
                            onClick={() => handleChangeOpen(item.templateName)}
                            loading={isLoading}
                    >{buttonLabel(item.isOpen)}</Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          ))}
          <Divider horizontal>
            <Header as='h4'>
              <Icon name='mail outline' />
              Send Email
            </Header>
          </Divider>

          <SendEmail selectTemplate={selectTemplate}/>

          <Divider horizontal>
            <Header as='h4'>
              <Icon name='bar chart outline' />
              Email Stats
            </Header>
          </Divider>

          <Table 
            celled 
            striped 
            singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Tag</Table.HeaderCell>
                <Table.HeaderCell>Sent</Table.HeaderCell>
                <Table.HeaderCell>Delivered</Table.HeaderCell>
                <Table.HeaderCell>Failed</Table.HeaderCell>
                <Table.HeaderCell>Opened</Table.HeaderCell>
                <Table.HeaderCell>Unique Opened</Table.HeaderCell>
                <Table.HeaderCell>Unique Clicked</Table.HeaderCell>
                <Table.HeaderCell>Complained</Table.HeaderCell>
                <Table.HeaderCell>Unsubscribed</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {tagStats.map((item) => (           
                  <Table.Row key={item.id}>
                      <Table.Cell>
                        {item.tag}
                      </Table.Cell>
                      <Table.Cell>
                        {item.accepted}
                      </Table.Cell>
                      <Table.Cell>
                        {item.delivered + ' (' + percentageCalculator(item.accepted, item.delivered) + '%)'}
                      </Table.Cell>
                      <Table.Cell>
                        {item.failed + ' (' + percentageCalculator(item.accepted, item.failed) + '%)'}
                      </Table.Cell>
                      <Table.Cell>
                        {item.opened}
                      </Table.Cell>
                      <Table.Cell>
                        {item.uniqueOpened + ' (' + percentageCalculator(item.accepted, item.uniqueOpened) + '%)'}
                      </Table.Cell>
                      <Table.Cell>
                        {item.uniqueClicked}
                      </Table.Cell>
                      <Table.Cell>
                        {item.complained}
                      </Table.Cell>
                      <Table.Cell>
                        {item.unsubscribed}
                      </Table.Cell>
                  </Table.Row>
            ))}
            </Table.Body>
          </Table>
          <Message 
            info
            attached='bottom'
            floating
          >
            <Message.Header>
              <Icon name='info' />
              Info
            </Message.Header>
            <Table basic='very' compact>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Sent</Table.Cell>
                  <Table.Cell>The mail server accepted the request to send/forward the email and the message has been placed in queue.</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Delivered</Table.Cell>
                  <Table.Cell>The mail server sent the email and it was accepted by the recipient email server.</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Failed</Table.Cell>
                  <Table.Cell>The mail server could not deliver the email to the recipient email server.</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Opened</Table.Cell>
                  <Table.Cell>The email recipient opened the email. Multiple opens are counted</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Unique Opened</Table.Cell>
                  <Table.Cell>Unique opens are the number of users who have opened an email, counting only one open per user, even if the user has opened the email several times.</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Unique Clicked</Table.Cell>
                  <Table.Cell>Unique clicks are the number of users who have clicked on a link, counting only one click per user, even if the user has visited the link several times.</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Complained</Table.Cell>
                  <Table.Cell>The email recipient clicked on the spam complaint button within their email client.</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Unsubscribed</Table.Cell>
                  <Table.Cell>The email recipient clicked on the unsubscribe link.</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Message>
        </>)}

    </div>
  );
};

export default Email;