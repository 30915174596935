import React from 'react';
import LogoutButton from '../LogoutButton/LogoutButton';
import './Header.css';
import Logo from '../../images/PTAlogo.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
  <div className='header'>
    <Link to='/main' className='logo-box'>
      <img src={Logo} height='40px' alt="logo" />
      <span className='page-title'><b>EZ</b>PURIM ADMIN</span>
    </Link>

    <LogoutButton />
  </div>
)};

export default Header;
