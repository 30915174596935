import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Popup, Table, Icon} from "semantic-ui-react";
import "./CustomersFormOrder.css"
import {BACKEND_URL, FRONTEND_URL, JWT_KEY, YEAR_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from "react-toastify";

const CustomersFormOrder = ({customerId, uuid}) => {
	const [open, setOpen] = useState(false)
	const [ordersGiving, setOrdersGiving] = useState([]);
	const [ordersReceiving, setOrdersReceiving] = useState([]);
	const [customerPekalach, setCustomerPekalach] = useState([]);
	const [copied, setCopied] = useState(false);

	const buttonLabel = (open) => (open ? 'Close' : 'Orders');
	
	useEffect(() => {
		fetch(`${BACKEND_URL}api/v1/orders/admin/giving/summary/${customerId}?year=${getWithExpiry(YEAR_KEY)}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setOrdersGiving(result.map(o => ({...o, id: randomId()})))
			})
			
			.catch((error) => console.log('No way! ', error));
		
		fetch(`${BACKEND_URL}api/v1/orders/admin/receiving/summary/${customerId}?year=${getWithExpiry(YEAR_KEY)}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setOrdersReceiving(result.map(o => ({...o, id: randomId()})))
			})
			
			.catch((error) => console.log('No way! ', error));
		
		fetch(`${BACKEND_URL}api/v1/item/orders/admin/customer/summary/${customerId}?year=${getWithExpiry(YEAR_KEY)}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setCustomerPekalach(result.map(o => ({...o, id: randomId()})))
			})
			
			.catch((error) => console.log('No way! ', error));
		
	}, [])
	
	const handleChangeValue = () => {
		if (!open) {
			return setOpen(true);
		}
		setOpen(false);
	};
	
	
	function randomId() {
		return Math.random();
	};
	
	
	const routerUuid = () => {
		window.open(FRONTEND_URL+`/login?uuid=${uuid}`, '_blank');
	};
	
	
	return (
		<div className="orders-item">
			<div className="button-container">
				<Button onClick={() => handleChangeValue()}
				        basic color='green'>{buttonLabel(open)}</Button>
				<Button
					basic color='blue'
					onClick={() => {
						routerUuid()
					}}
				>Login as Customer</Button>
				<CopyToClipboard
					text={FRONTEND_URL+`/login?uuid=${uuid}`}
					onCopy={() => toast('Copied!', {
							position: "bottom-right",
							autoClose: 1000,
							hideProgressBar: true
						})
					}
				>
					<Button
						basic
						icon="copy outline"
						size="big"
					/>
				</CopyToClipboard>
			</div>
			{open ? (<>
				<h5>Orders Giving</h5>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={10} textAlign={"center"}>Giving To</Table.HeaderCell>
							<Table.HeaderCell textAlign={"center"}>Reciprocated</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{ordersGiving.map((item) => <Table.Row key={item.id}>
							<Table.Cell>{item.name}</Table.Cell>
							<Table.Cell textAlign={"center"}>
							{
								item.reciprocated && <Icon name="checkmark" />
							}
							</Table.Cell>
						</Table.Row>)}
					</Table.Body>
				</Table>
				<h5>Orders Receiving</h5>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={10} textAlign={"center"}>Receiving From</Table.HeaderCell>
							<Table.HeaderCell textAlign={"center"}>Reciprocated</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{ordersReceiving.map((item) => <Table.Row key={item.id}>
							<Table.Cell>{item.name}</Table.Cell>
							<Table.Cell textAlign={'center'}>
							{
								item.reciprocated && <Icon name="checkmark" />
							}
							</Table.Cell>
						</Table.Row>)}
					</Table.Body>
				</Table>
				<h5>Pekalach</h5>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={10} textAlign={"center"}>Name</Table.HeaderCell>
							<Table.HeaderCell textAlign={"center"}>Quantity</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{customerPekalach.map((item) => <Table.Row key={item.id}>
							<Table.Cell>{item.name}</Table.Cell>
							<Table.Cell textAlign={'center'}>
								{item.quantity}
							</Table.Cell>
						</Table.Row>)}
					</Table.Body>
				</Table>
				<div className={"close-button"}>
					<Button onClick={() => handleChangeValue()}
					        basic color='green'>Close</Button>
				</div>
			</>) : null}
		</div>
	);
};

export default CustomersFormOrder;