import React from 'react';
import Header from '../Header/Header';
import AppSidebar from '../Sidebar/AppSidebar';
import './PageLayout.css';

const PageLayout = ({ children }) => {
  return (
    <div className='layout'>
      <Header />
      <AppSidebar>
        {children}
      </AppSidebar>
    </div>
)};

export default PageLayout;
