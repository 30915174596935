import React, {useState} from 'react';
import {Button, Checkbox, Form, Modal} from 'semantic-ui-react'
import "./NewPekalachScreen.css"
import {BACKEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {useHistory} from "react-router-dom";

const NewPekalachScreen = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false)
  const [dataImg, setDataImg] = useState(null)
  const [name, setName] = useState("");
  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState("");
  const [salePrice, setSalePrice] = useState(null);
  const [soldOut, setSoldOut] = useState(false);
  const [error, setError] = useState("")
  const [openErrorMassage, setOpenErrorMassage] = useState(false)
  const [image, setImage] = useState(null)
  const [color, setColor] = useState("");
  const [itemQuantity, setItemQuantity] = useState(0);
  const [quantitySold, setQuantitySold] = useState(0);
  
  const colorSet = {
    options: [
      {value: 'red', text: 'Red'},
      {value: 'blue', text: 'Blue'},
      {value: 'green', text: 'Green'},
      {value: 'pink', text: 'Pink'},
      {value: 'orange', text: 'Orange'},
    ],
    selected: '',
  }
  
  
  const createPekalach = async (newItem) => {
    
    setIsLoading(true)
    const response = await fetch(
      `${BACKEND_URL}api/v1/item/admin`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
        body: JSON.stringify(newItem),
      }
    ).catch((err) => {
      console.error(`Error CreateItem:`, err);
      return {errors: err};
    });
    if (!response.ok) {
      const errors = await response.json();
      setIsLoading(false);
      setError(`Error status:${errors.status}`)
      setOpenErrorMassage(true)
      console.log('ERROR in response (CreateItem): ', errors);
      return {errors};
    }
    setTimeout(() => {
      setIsLoading(false);
      history.push('/pekalach');
    }, 2500);
    const data = await response.json();
    console.log('RESULT: ', data);
    return data;
  };
  
  const updateImage = async (dataImg, itemId) => {
    setIsLoading(true)
    const response = await fetch(
      `${BACKEND_URL}api/v1/item/admin/upload/${itemId}`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
        body: dataImg,
      }
    ).catch((err) => {
      console.error(`Error updateImage:`, err);
      return {errors: err};
    });
    if (!response.ok) {
      const errors = await response.json();
      setIsLoading(false);
      setError(`Error status:${errors.status}`)
      setOpenErrorMassage(true)
      console.log('ERROR in response (updateImage): ', errors);
      return {errors};
    }
    setTimeout(() => {
      setIsLoading(false);
      history.push('/pekalach');
    }, 1500);
  };
  
  const handlerImg = (e) => {
    const dataImg = new FormData()
    const files = e.target.files[0];
    setImage(`: ${files.name}`)
    dataImg.append("file", files)
    setDataImg(dataImg)
  }
  const handlerNamePekalach = (e) => {
    setName(e.target.value)
  }
  const handlerColorPekalach = (event, {value}) => {
    setColor(value);
  }
  const handlerPricePekalach = (e) => {
    setPrice(e.target.value)
  }
  const handlerDescriptionPekalach = (e) => {
    setDescription(e.target.value)
  }
  const handlerSalePricePekalach = (e) => {
    setSalePrice(e.target.value)
  }
  const quantityChangeHandler = (e) => {
    setItemQuantity(e.target.value);
  }
  const handlerSavePekalach = () => {
    const item = {
      color,
      description,
      imageUrl: "",
      name,
      price,
      salePrice,
      itemQuantity,
      soldOut,
    }
    createPekalach(item).then((data) => {
      if (dataImg) {
        updateImage(dataImg, data.itemId)
      }
    });
  }
  
  return (
    <div className={"new-template-screen"}>
      <h2> New Pekalach </h2>
      <Form className="form-template">
        <div className="upload-file__wrapper">
          <Form.Input
            type="file"
            name="files[]"
            id="upload-file__input_1"
            className="upload-file__input"
            onChange={(e) => {
              handlerImg(e)
            }}
          />
          <label className="upload-file__label" htmlFor="upload-file__input_1">
            <span className="upload-file__text">Attach image {image} </span>
          </label>
        
        </div>
        
        <Form.Group widths='equal'>
          <Form.Input
            onChange={(e) => handlerNamePekalach(e)}
            label='Name'
            placeholder='Name'
            value={name}
          />
          <Form.Select
            options={colorSet.options}
            onChange={handlerColorPekalach}
            label='Color'
            placeholder='Select color'
          />
          <Form.Input
            onChange={(e) => handlerPricePekalach(e)}
            label='Price'
            fluid selection
            placeholder='Price'
            value={price}
            type={"number"}
          />
        </Form.Group>
        <Form.TextArea
          onChange={(e) => handlerDescriptionPekalach(e)}
          label='Description'
          placeholder='Description'
          value={description}
        />
        <Form.Group widths='equal'>
          <Form.Input
            onChange={(e) => handlerSalePricePekalach(e)}
            label='Sale Price'
            placeholder='Sale Price'
            value={salePrice}
            type={"number"}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            onChange={(e) => quantityChangeHandler(e)}
            label='Quantity for Sale'
            placeholder='0'
            value={itemQuantity}
            type={"number"}
          />
        </Form.Group>
        <div className={"button-create"}>
          <Button
            onClick={() => handlerSavePekalach()}
            primary
            loading={isLoading}
          >
            Create Pekalach
          </Button>
          <Button
            onClick={() => {
              history.push('/pekalach');
            }}
            basic
            color="red"
            className="ez-basic-btn"
          >
            Cancel
          </Button>
        </div>
      </Form>
      <Modal
        size={"mini"}
        open={openErrorMassage}
        onClose={() => setOpenErrorMassage(false)}
        onOpen={() => setOpenErrorMassage(true)}
      >
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenErrorMassage(false)}>OK</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default NewPekalachScreen;