import React, {useEffect, useState} from 'react';
import {Button, Icon, Image, Table} from 'semantic-ui-react'
import {BACKEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import "./EditPekalach.css"
import {useHistory} from "react-router-dom";
import EditPekalachForm from "../EditPekalachForm/EditPekalachForm";
import {default as NumberFormat} from "react-number-format";

const EditPekalach = () => {
  
  const buttonLabel = (open) => (open ? 'Cancel' : 'View');
  const [pekalachEdit, setPekalachEdit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [saveChange, setSaveChange] = useState([]);
  const history = useHistory();
  
  useEffect(() => {
    setIsLoading(true);
    fetch(`${BACKEND_URL}api/v1/item`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setPekalachEdit(result.map((obj) => ({
          ...obj, isOpen: false
        })))
        setIsLoading(false)
      })
      .catch((error) => console.log('No way! ', error));
  }, [saveChange])
  
  const handleChangeOpen = (id) => {
    const changeOpen = pekalachEdit.map(item => {
      if (id === item.itemId) {
        const edit = {
          ...item,
          isOpen: !item.isOpen
        };
        return edit;
      }
      return item
    })
    setPekalachEdit(changeOpen)
  };
  const ChangePekalach = (item) => {
    setSaveChange(item);
  };
  
  const handlerDelete = (id) => {
    const filteredItem = pekalachEdit.filter((item) => item.itemId !== id);
    setPekalachEdit(filteredItem);
  };
  
  return (
    <div className={"pekalach-screen"}>
      {isLoading
        ? <h3 style={{margin: "50px", textAlign: "center"}}>Loading...</h3>
        : (<>
          <div className="add-new-pekalach">
            <Button
              onClick={() => history.push('/new-pekalach')}
              basic
              color="blue"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon name="plus" size="large"/>
                <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
                Add New Pekalach
              </span>
              </div>
            </Button>
          </div>
          {pekalachEdit.sort((a, b) => b.itemId - a.itemId).map((card) => (
            <Table color={card.color} key={card.itemId}>
              <Table.Body>
                <Table.Row style={{height: '250px'}}>
                  {card.isOpen ?
                    <Table.Cell>
                      <EditPekalachForm ChangePekalach={ChangePekalach} handlerDelete={handlerDelete} item={card}/>
                    </Table.Cell>
                    : <><Table.Cell width={1}>
                      <Image size={"small"} src={card.imageUrl}/>
                    </Table.Cell>
                      <Table.Cell width={4}>
                        <h3>{card.name}</h3>
                        <h5>{card.description}
                        </h5></Table.Cell>
                      <Table.Cell textAlign={"center"} width={1}>
                        <h4>
                          <NumberFormat value={card.price} displayType={'text'} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />
                        </h4></Table.Cell></>}
                  <Table.Cell textAlign={"center"} width={1}>
                    <Button basic color="blue"
                            onClick={() => handleChangeOpen(card.itemId)}
                            loading={isLoading}
                    >{buttonLabel(card.isOpen)}</Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          ))}
        </>)}
    </div>
  );
};

export default EditPekalach;