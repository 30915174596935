import jwt_decode from 'jwt-decode';
import { useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthenticationProvider';
import { JWT_KEY } from '../config';
import { getWithExpiry } from '../services/localStorageService';

export const useJwtToken = () => {
  const { setAuthenticated } = useContext(AuthenticationContext);

  const isJwtValid = () => {
    const accessToken = getWithExpiry(JWT_KEY);
    if (!accessToken) {
      return false;
    }

    let decodedAccessToken = jwt_decode(accessToken);
    const nowInMillis = new Date().getTime();
    const jwtExpirationInMillis = decodedAccessToken.exp * 1000;
    if (nowInMillis < jwtExpirationInMillis) {
      setAuthenticated(true);
      return true;
    }

    setAuthenticated(false);
    return false;
  };

  return { isJwtValid };
};
