import React from 'react';
import error_img from '../../images/status_error.svg';
import classnames from 'classnames';
import './EZInput.css';

const EZInput = ({
  errors = {},
  className = '',
  label = '',
  labelClassName = '',
  name,
  type = 'input',
  value,
  handleValueChange = (e) => {},
  handleKeyDown = (e) => {},
  optional = false,
  inputProps = {},
}) => (
  <div className={className}>
    {label && (
      <label className={labelClassName} htmlFor={name}>
        {label}
      </label>
    )}
    <input
      name={name}
      type={type}
      value={value}
      className={classnames('ezinput', {
        error: errors[name],
      })}
      onChange={handleValueChange}
      onKeyDown={handleKeyDown}
      {...inputProps}
    ></input>
    {optional && <div className="help-text">Optional</div>}
    {errors[name] && (
      <div className="error-message-div">
        <img src={error_img} alt="error-message-icon" height="20px" />
        <span className="error-message">{errors[name]}</span>
      </div>
    )}
  </div>
);

export default EZInput;
