import React, {useEffect, useState} from 'react';
import {Button, Modal, Icon, Pagination, Select, Loader, Dimmer, Table, Input, Form} from "semantic-ui-react";
import style from "./TableOfOrders.css"
import {BACKEND_URL, JWT_KEY, YEAR_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import moment from "moment";
import {CSVLink} from "react-csv";
import SearchFieldIcon from './SearchFieldIcon'

const TableOfOrders = () => {

  const [selectYear, setSelectYear] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [defaultTable, setDefaultTable] = useState([])
  const [tableOptions, setTableOptions] = useState({page: 0, year: getWithExpiry(YEAR_KEY), sortBy: 'sortBy=createdAt', desc: true, search: ''});
  const [totalPages, setTotalPages] = useState(0);
  const [sortDirection, setSortDirection] = useState("descending");
  const [desc, setDesc] = useState(true);
  const [sortColumn, setSortColumn] = useState("createdAt")
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [openOrderEditModal, setOpenOrderEditModal] = useState(false);
  const [openNoResults, setOpenNoResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [people, setPeople] = useState([]);
  const [from, setFrom] = useState('');
  const [orderId, setOrderId] = useState('');
  const [to, setTo] = useState('');
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setIsLoading(true)
    fetch(`${BACKEND_URL}api/v1/orders/admin/years`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setSelectYear(result.map((w) => ({value: w, text: w})))
      })
      .catch((error) => console.log('No way! ', error));

    fetch(`${BACKEND_URL}api/v1/orders/admin?page=0&size=25&sortBy=createdAt&year=${getWithExpiry(YEAR_KEY)}&desc=true`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setTotalPages(result.totalPages);
        setDefaultTable(result.orders) 
      })
      .catch((error) => console.log('No way! ', error));

      fetch(`${BACKEND_URL}api/v1/customer/admin/summary?year=${getWithExpiry(YEAR_KEY)}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      })
        .then((response) => response.json())
        .then((people) => {
          people.sort(({ lastName }, { lastName: otherLastName }) =>
            lastName.trim().localeCompare(otherLastName.trim())
          );
          const options = []
          people.map((person) =>{
            let row = {key: person.customerId, text: person.title + ' ' + person.firstNames + ' ' + person.lastName + '--' + person.addressLine1, value: person.customerId}
            options.push(row);
          })
          setPeople(options);
        })
        .catch((error) => console.log('No way! ', error));

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setDefaultTable(defaultTable)
  }, [defaultTable])

  const yearSetSelect = {
    options: selectYear,
    selected: "",
  }

  const getSelectYear = ({page, sortBy, year, desc, search}) => {
    let searchString = ''
    if(search) {
      searchString = search
    }
    fetch(`${BACKEND_URL}api/v1/orders/admin?page=${page}&size=25&${sortBy}&year=${year}&desc=${desc}&search=${searchString}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setTotalPages(result.totalPages);
        setDefaultTable(result.orders)
      })

      .catch((error) => console.log('No way! ', error));
  }

  const deleteOrder = async (orderId, fromCustomerId) => {
    const response = await fetch(
      `${BACKEND_URL}api/v1/orders/admin/`+orderId+'/'+fromCustomerId,
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        }
      }
    ).then(() => {
      getSelectYear({...tableOptions});
    }).catch((err) => {
      console.error(`Error deleting order:`, err);
      return {errors: err};
    });
  }

  const getAllOrders = () => {
    setIsLoading(true)
    fetch(`${BACKEND_URL}api/v1/orders/admin?page=0&size=1000000&year=${tableOptions.year}&${tableOptions.sortBy}&desc=${desc}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if(result.orders.length>0) {
          const orders = result.orders.map(
            ({
              fromCustomer,
              toCustomer,
              createdAt,
              reciprocated,
             }) => {
               let created = moment(createdAt).format('L LT')
              return {
                fromCustomer,
                toCustomer,
                created,
                reciprocated
              };
            }
          );
          setDownloadCSV(orders);
          setIsLoading(false)
          setOpenMessageModal(true);
        } else {
          setOpenNoResults(true);
        }
      })

      .catch((error) => console.log('No way! ', error));
  }

  const updateOrder = async () => {
		const response = await fetch(
			`${BACKEND_URL}api/v1/orders/admin/order`,
			{
				method: 'PUT',
				mode: 'cors',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
				},
				body: JSON.stringify({
					orderId: orderId,
					toCustomerId: to,
          price: amount
				}),
			}
		).catch((err) => {
			console.error(`Error updateOrder:`, err);
			return {errors: err};
		});
		
		if (!response.ok) {
			const errors = await response.json();
			console.log('ERROR in response (updateOrder): ', errors);
			return {errors};
		}
		
		const data = await response.json();
    setOpenOrderEditModal(false);
    getSelectYear({...tableOptions});
		console.log('RESULT: ', data);
		return {data};
	};

  const handleYearChange = (event, {value}) => {
    setTableOptions((prevState) => ({...prevState, year: value}));
    getSelectYear({...tableOptions, year: value});
  }

  const paginate = (pageNumber) => {
    setTableOptions((prevState) => ({...prevState, page: pageNumber}));
    getSelectYear({...tableOptions, page: pageNumber - 1});
  };

  const handleSearch = (searchText) => {
    setSearchTerm(searchText);
    setTableOptions((prevState) => ({...prevState, search: searchText, page: 0}));
    getSelectYear({...tableOptions, search: searchText, page: 0});
  }

  const handleSort = (column) => {
    if(sortColumn === column) {
      setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
      setTableOptions((prevState) => ({...prevState, desc: !desc}));
      getSelectYear({...tableOptions, desc: !desc});
      setDesc(!desc);
    } else {
      setSortColumn(column);
      setSortDirection('ascending');
      setDesc(false);
      setTableOptions((prevState) => ({...prevState, sortBy: 'sortBy='+column}));
      getSelectYear({...tableOptions, sortBy: 'sortBy='+column, desc: false});
    }
  }

  const handleRowClick = (from, orderId, to, amount) => {
    setFrom(from);
    setTo(to);
    setAmount(amount);
    setOrderId(orderId)
    setOpenOrderEditModal(true)
  }

  const handleDropDownSelect = (event, {value}) => {
    setTo(value);
  }

  const handleAmountChange = (e, {name, value}) => {
    setAmount(value);
  }

  return (
    <div className={style["content-table-orders"]}>
      {isLoading && 
          <Dimmer active inverted>
            <Loader active content='Retrieving Data' inverted size='massive'/>
          </Dimmer>
      }
      <h1 className={style["title"]}>Orders</h1>
      <div className={style["select-group"]}>
        <div className={style["select-left"]}>
          <span>Year: </span>
          <Select
            defaultValue={getWithExpiry(YEAR_KEY)}
            options={yearSetSelect.options}
            placeholder='Select year'
            onChange={handleYearChange}
          />
          
          <Input
            icon={<Icon name="search" />}
            className={style["search"]}
            placeholder="Search.."
            value={searchTerm}
            onChange={e => handleSearch(e.target.value)}
          >
            <input />
            <SearchFieldIcon
              searchValue={searchTerm}
              setSearchValue={setSearchTerm}
              handleSearch={handleSearch}
            />
          </Input>
        </div>
        <div className={style["select-right"]}>
          <Button
            onClick={() => getAllOrders()}
            color='green'
            >
            <div className='button'>
              <Icon size="large" name="file excel"/>
              <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
              Download
              </span>
            </div>
          </Button>
		  	</div>
      </div>
      
      <Table striped color={"green"} compact sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign={"left"}
              sorted={sortColumn === 'fromCustomer' ? sortDirection : null}
              onClick={() => handleSort('fromCustomer')}
            >
              From
            </Table.HeaderCell>
            <Table.HeaderCell textAlign={"left"}
              sorted={sortColumn === 'toCustomer' ? sortDirection : null}
              onClick={() => handleSort('toCustomer')}
            >
              To
            </Table.HeaderCell>
            <Table.HeaderCell textAlign={"left"}
              sorted={sortColumn === 'invoiceId' ? sortDirection : null}
              onClick={() => handleSort('invoiceId')}
            >
              Invoice Id
            </Table.HeaderCell>
            <Table.HeaderCell textAlign={"left"}
              sorted={sortColumn === 'reciprocated' ? sortDirection : null}
              onClick={() => handleSort('reciprocated')}
            >Reciprocated </Table.HeaderCell>
            <Table.HeaderCell textAlign={"left"}
              sorted={sortColumn === 'createdAt' ? sortDirection : null}
              onClick={() => handleSort('createdAt')}
            >Created</Table.HeaderCell>
            <Table.HeaderCell textAlign={"left"}
            >Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {defaultTable.map((item) => (
            <Table.Row 
              key={item.orderId} 
              onClick={() => handleRowClick(item.fromCustomer, item.orderId, item.toCustomerId, item.price)}
              className='tableRow'
            >
              <Table.Cell>{item.fromCustomer}</Table.Cell>
              <Table.Cell>{item.toCustomer}</Table.Cell>
              <Table.Cell>{item.invoiceId}</Table.Cell>
              <Table.Cell textAlign={"center"}>
                {item.reciprocated ? <Icon name={"checkmark"}/> : null}
              </Table.Cell>
              <Table.Cell textAlign={"left"}>{moment(item.createdAt).format('L LT')}</Table.Cell>
              <Table.Cell textAlign={"center"}>
                <Icon name="delete" color="red" link onClick={() => 
                  window.confirm('Are you sure you wish to delete this order?') ? deleteOrder(item.orderId, item.fromCustomerId) : console.log('canceled')}/>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="pagination-greetings">
        <Pagination
          boundaryRange={0}
          defaultActivePage={tableOptions.page+1}
          siblingRange={2}
          onPageChange={(event, data) => paginate(data.activePage)}
          // activePage={tableOptions.page+1}
          totalPages={totalPages}/>
      </div>
      
      <Modal
        size={"mini"}
        open={openMessageModal}
        onClose={() => setOpenMessageModal(false)}
        onOpen={() => setOpenMessageModal(true)}
      >
      <Modal.Header>Orders Report</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Orders report successfully generated!
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenMessageModal(false)}>Cancel</Button>
          <CSVLink data={downloadCSV} filename="Orders.csv">
            <Button style={{marginLeft: '15px'}} basic color={"green"}
                    onClick={() => setOpenMessageModal(false)}>Download</Button>
          </CSVLink>
        </Modal.Actions>
      </Modal>

      <Modal
        size={"mini"}
        open={openNoResults}
        onClose={() => setOpenNoResults(false)}
        onOpen={() => setOpenNoResults(true)}
      >
        <Modal.Header>No Results</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            There are no results!
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenNoResults(false)}>Close</Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={openOrderEditModal}
        onClose={() => setOpenOrderEditModal(false)}
      >
      <Modal.Header>Edit Order</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group width='equal'>
                <Form.Field>
                  <label>From</label>
                  {from}
                </Form.Field>
                <Form.Field
                  control={Select}
                  label='To'
                  options={people}
                  defaultValue={to}
                  onChange={handleDropDownSelect}
                  search
                />
                <Form.Field
                  control={Input}
                  label='Amount'
                  value={amount}
                  onChange={handleAmountChange}
                />
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenOrderEditModal(false)}>Cancel</Button>
          <Button basic color={"green"} onClick={() => updateOrder()}>Submit</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default TableOfOrders;