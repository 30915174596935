import React, { useState, useEffect, useRef } from 'react';
import PeopleTable from './PeopleTable';
import { Input, Icon, Button } from 'semantic-ui-react';
import './SearchScreen.css';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
import SearchFieldIcon from './SearchFieldIcon';
import { getWithExpiry } from '../services/localStorageService';
import LogoutButton from './LogoutButton/LogoutButton';
import { EditCustomerContext } from '../contexts/EditCustomerProvider';
import { useContext } from 'react';
import { CustomersContext } from '../contexts/CustomersProvider';
import { useHistory } from 'react-router-dom';

const PPL_TO_DISPLAY = 25;

const totalPages = (records) => Math.ceil(records / PPL_TO_DISPLAY) || 1;

const SearchScreen = () => {
  const history = useHistory();
  const {
    isLoading,
    people,
    filteredPeople,
    setFilteredPeople,
    displayedPeople,
    setDisplayedPeople,
  } = useContext(CustomersContext);
  const { editInProgress } = useContext(EditCustomerContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [selections, setSelections] = useState(
    getWithExpiry('ezp.admin.selections') || {}
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearchTermChange = (event) => setSearchTerm(event.target.value);
  const debouncedSearchTerm = useDebouncedValue(searchTerm);


  useEffect(() => {
    const filtered = people.filter(
      ({ firstNames, lastName, title, addressLine1, gaveInPast }) => {
        const fullNameAddress = `${lastName} ${title} ${firstNames} ${addressLine1}`;
        if (searchTerm) {
          const searchTermParts = searchTerm.split(' ');
          return searchTermParts.every((searchTermPart) =>
            fullNameAddress
              .toLowerCase()
              .includes(searchTermPart.toLocaleLowerCase())
          );
        }

        return true;
      }
    );
    setFilteredPeople(filtered);
    setDisplayedPeople(filtered.slice(0, PPL_TO_DISPLAY));
  }, [debouncedSearchTerm, people]);

  useEffect(() => {
    const offset = (currentPage - 1) * PPL_TO_DISPLAY;
    setDisplayedPeople(
      filteredPeople.slice(0 + offset, PPL_TO_DISPLAY + offset)
    );
  }, [currentPage, filteredPeople]);

  const lastPage = totalPages(filteredPeople.length);
  const searchInputEl = useRef(null);

  return (
    <div className="search-screen">
      <div className="search-area-with-match-display">
        <div className="search-top-row">
          <div>
            <Input
              disabled={editInProgress}
              icon={<Icon name="search" />}
              className="search-field"
              placeholder="Search for names and addresses"
              value={searchTerm}
              onChange={handleSearchTermChange}
            >
              <input ref={searchInputEl} style={{ borderRadius: '100px' }} />
              <SearchFieldIcon
                searchValue={searchTerm}
                setSearchValue={setSearchTerm}
                searchRef={searchInputEl}
              />
            </Input>
          </div>
        </div>

        {searchTerm &&
          !isLoading &&
          filteredPeople.length !== people.length && (
            <div className="search-results-text">
              Displaying {filteredPeople.length} search results
            </div>
          )}
      </div>

      {isLoading && <h3>Loading...</h3>}
      {isLoading || (
        <>
          <Button
            disabled={editInProgress}
            basic
            color="blue"
            className="add-new-customer"
            onClick={() => history.push('/new-customer')}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon name="user plus" size="large" />
              <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                Add New Customer
              </span>
            </div>
          </Button>

          <PeopleTable
            people={displayedPeople}
            searchTerm={debouncedSearchTerm}
            selections={selections}
            setSelections={setSelections}
          />

          <div className="pagination-buttons">
            <Button
              disabled={currentPage === 1 || editInProgress}
              className="btn-previous"
              compact
              primary
              active={false}
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              onClick={(e) => {
                setCurrentPage(currentPage - 1);
              }}
            >
              <Icon name="angle left" />
              <span>Previous</span>
            </Button>
            <div className="page-x-of-y">
              Page {currentPage} of {lastPage} {'  '}{' '}
            </div>
            <Button
              disabled={currentPage === lastPage || editInProgress}
              className="btn-next"
              compact
              primary
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              onClick={(e) => {
                setCurrentPage(currentPage + 1);
              }}
            >
              <span>Next</span>
              <Icon name="angle right" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchScreen;
