import { Bar } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import { BACKEND_URL, JWT_KEY } from '../../../config';
import { getWithExpiry } from '../../../services/localStorageService';

const VerticalBar = () => {
  const [revenue, setRevenue] = useState([]);

  useEffect(() => {
    fetch(`${BACKEND_URL}api/v1/reports/revenue/consolidated?year=0`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setRevenue(result);
      })

      .catch((error) => console.log('No way! ', error));
  }, []);

  useEffect(() => {
    setRevenue(revenue);
  }, [revenue]);

  if (!revenue) {
    return;
  }

  let years = revenue.map((item) => {
    return item.year.toString();
  });
  let grandTotals = revenue.map((item) => {
    return item.grandTotal;
  });
  let totalFromAllOrders = revenue.map((item) => {
    return item.totalFromAllOrders;
  });
  let totalFromOrdersNonRecip = revenue.map((item) => {
    return item.totalFromOrdersNonRecip;
  });
  let totalFromOrdersRecip = revenue.map((item) => {
    return item.totalFromOrdersRecip;
  });
  let totalFromItems = revenue.map((item) => {
    return item.totalFromItems;
  });

  const color_scheme = [
    '#a6cee3',
    '#1f78b4',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
  ];
  let colors = color_scheme.slice(0, 5);
  const data = {
    labels: years,
    datasets: [
      {
        label: 'Pekalach',
        data: totalFromItems,
        backgroundColor: colors[0],
        minBarLength: 30,
      },
      {
        label: 'Reciprocated SM Orders',
        data: totalFromOrdersRecip,
        backgroundColor: colors[1],
        minBarLength: 50,
      },
      {
        label: 'Non-Reciprocated SM Orders',
        data: totalFromOrdersNonRecip,
        backgroundColor: colors[2],
        minBarLength: 60,
      },
      {
        label: 'All SM Orders',
        data: totalFromAllOrders,
        backgroundColor: colors[3],
        minBarLength: 70,
      },
      {
        label: 'Total Revenue',
        data: grandTotals,
        backgroundColor: colors[4],
        minBarLength: 80,
      },
    ],
  };

  const config = {
    type: 'bar',
    data: data,
    options: {
      plugins: {
        title: {
          display: true,
          text: 'Yearly Revenue',
        },
        datalabels: {
          color: 'white',
          font: {
            weight: 'bold',
          },
          formatter: function (value, context) {
            return (value).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2});
					},
          anchor: 'end',
          align: 'bottom',
          display: 'auto',
        },
        // legend: {
        //   display: false
        // }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Year',
          },
          stacked: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <>
      <Bar data={data} options={config.options} height={'300px'} />
    </>
  );
};
export default VerticalBar;
