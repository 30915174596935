import React, {useEffect, useState} from 'react';
import {BACKEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {Checkbox, Table} from "semantic-ui-react";

const Cities = () => {
	
	const [cities, setCities] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	
	
	useEffect(() => {
		setIsLoading(true)
		fetch(`${BACKEND_URL}api/v1/city`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setCities(result.sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0)))
				setIsLoading(false)
			})
			
			.catch((error) => console.log('No way! ', error));
		
	}, [])
	
	
	const updateDeliverableCity = async (citiesObject) => {
		const response = await fetch(
			`${BACKEND_URL}api/v1/city/admin/${citiesObject.cityId}`,
			{
				method: 'PUT',
				mode: 'cors',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
				},
				body: JSON.stringify({
					city: citiesObject.city,
					deliverable: citiesObject.deliverable
				}),
			}
		).catch((err) => {
			console.error(`Error updateCitiesAdmin:`, err);
			return {errors: err};
		});
		
		if (!response.ok) {
			const errors = await response.json();
			console.log('ERROR in response (updateCitiesAdmin): ', errors);
			return {errors};
		}
		
		const data = await response.json();
		console.log('RESULT: ', data);
		return {data};
	};
	
	
	const handleChangeDeliverable = (id) => {
		
		const changeDeliverable = cities.map(item => {
			if (id === item.cityId) {
				const citiesObject = {
					...item,
					deliverable: !item.deliverable
				};
				updateDeliverableCity(citiesObject)
				return citiesObject;
			}
			return item
		})
		setCities(changeDeliverable)
	};

	const getCity = (item) => {
		var city = item.city;
		if(item.state) {
			city = city + ', ' + item.state;
		}
		return city;
	}
	
	return (
		<div className="content-table">
			{isLoading ? <h3 style={{margin: "50px", textAlign: "center"}}>Loading...</h3> :
				(
					<>
						<h1 className="title">Deliverable Cities</h1>
						<Table color={"blue"}>
							<Table.Header textAlign={"center"}>
								<Table.Row>
									<Table.HeaderCell textAlign={"center"} width={4}>City</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"} width={6}>Deliverable</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{cities.map((item) => (<Table.Row key={item.cityId}>
									<Table.Cell>{getCity(item)}</Table.Cell>
									<Table.Cell textAlign={"center"}>
										<Checkbox
											onChange={() => {
												handleChangeDeliverable(item.cityId)
											}}
											checked={item.deliverable}
										/>
									</Table.Cell>
								</Table.Row>))}
							</Table.Body>
						</Table>
					</>
				)
			}
		</div>
	);
};

export default Cities;
