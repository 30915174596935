import {Pie} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {BACKEND_URL, JWT_KEY, YEAR_KEY} from "../../../config";
import {getWithExpiry} from "../../../services/localStorageService";
import { startCase } from "lodash";

const PieChart = () => {
	
	const [revenuePie, setRevenuePie] = useState([]);
	useEffect(() => {
		fetch(`${BACKEND_URL}api/v1/reports/revenue/consolidated?year=${getWithExpiry(YEAR_KEY)}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setRevenuePie(result[0])
			})
			
			.catch((error) => console.log('No way! ', error));
	}, [])
	
	const current_year = revenuePie;
	const colors = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a']
	
	const data = {
		labels: ['Pekalach', 'Reciprocated SM Orders', 'Non-Reciprocated SM Orders'],
		datasets: [
			{
				label: 'Pekalach',
				data: [current_year.totalFromItems, current_year.totalFromOrdersRecip, current_year.totalFromOrdersNonRecip],
				backgroundColor: colors.slice(0, 3),
				minBarLength: 50
			}
		]
	};
	
	function getPercent(data, value) {
		let total = data.reduce((a, b) => a + b, 0);
		return (Math.round(value / total * 100).toFixed(2));
	}
	
	const config = {
		type: 'pie',
		data: data,
		options: {
			plugins: {
				title: {
					display: true,
					text: 'Revenue (Current Year)'
				},
				datalabels: {
					color: 'white',
					font: {
						weight: 'bold'
					},
					formatter: function (value, context) {
						return parseFloat(value).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2}) 
							+ '\n' + getPercent(context.chart.data.datasets[0].data, value) + '%';
					},
					align: 'start',
					offset: function(context) {
						return context.dataIndex == 1 ? 50 : -100;
					}
				}
			},
			scales: {
				x: {
					title: {
						display: true,
						text: 'Total: ' + parseFloat(current_year.grandTotal).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2}) 
					},
					stacked: true
				},
				y: {
					beginAtZero: true,
					
				},
			},
		},
	};
	
	return (
		<>
			<Pie data={data} options={config.options} width={"250px"} height={"250px"} />
		</>
	)
};

export default PieChart;
