import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { JWT_KEY } from '../../config';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { removeItem } from '../../services/localStorageService';

const LogoutButton = () => {
  const { authenticated, setAuthenticated } = useContext(AuthenticationContext);
  let history = useHistory();

  return (
    <>
      {authenticated && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              removeItem(JWT_KEY);
              setAuthenticated(false);
              history.push('/');
            }}
          >
            Logout
          </Button>
        </div>
      )}
    </>
  );
};

export default LogoutButton;
