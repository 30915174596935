import React from 'react';
import { useContext } from 'react';
import { Icon } from 'semantic-ui-react';
import { EditCustomerContext } from '../contexts/EditCustomerProvider';

const SearchFieldIcon = ({ searchValue, setSearchValue, searchRef }) => {
  const { editInProgress } = useContext(EditCustomerContext);

  return searchValue ? (
    <Icon
      disabled={editInProgress}
      name="times circle"
      link
      onClick={() => {
        setSearchValue('');
        searchRef.current.focus();
      }}
    />
  ) : (
    <Icon name="search" />
  );
};

export default SearchFieldIcon;
