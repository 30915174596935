import { Doughnut } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import { Chart } from 'chart.js';
import { BACKEND_URL, JWT_KEY } from '../../../config';
import { getWithExpiry } from '../../../services/localStorageService';
import { useHistory } from 'react-router';

const UserLineChart = () => {
  const [userLine, setUserLine] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetch(`${BACKEND_URL}api/v1/reports/users/loggedin`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          console.log('Detected 403... Rerouting to login...');
          localStorage.clear();
          history.push('/login');
        }
        return response.json();
      })
      .then((result) => {
        setUserLine(result);
      })

      .catch((error) => console.log('No way! ', error));
  }, []);

  const colors = [
    '#a6cee3',
    '#1f78b4',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
  ];

  const data = {
    labels: ['Current Year Users', 'All Users', 'All Time Users'],
    datasets: [
      {
        label: 'Current Year',
        data: [
          userLine.loggedInCurrentYear,
          userLine.numberOfCustomers - userLine.loggedInCurrentYear,
        ],
        backgroundColor: [colors[0], colors[3]],
      },
      {
        label: 'All Years',
        data: [
          userLine.loggedInAllTime,
          userLine.numberOfCustomers - userLine.loggedInAllTime,
        ],
        backgroundColor: [colors[1], colors[3]],
      },
    ],
  };

  function getPercent(num) {
    return (Math.round(num * 100)).toFixed(2);
  }

  const config = {
    type: 'doughnut',
    data: data,
    options: {
      responsive: true,
      circumference: 200,
      rotation: -100,
      plugins: {
        datalabels: {
          color: 'white',
          display: function (context) {
            return context.dataset.data[context.dataIndex] > 15;
          },
          font: {
            weight: 'bold',
          },
          formatter: function (value, context) {
            if (context.dataIndex === 0) {
              return (
                value + '\n' + (getPercent(value / userLine.numberOfCustomers)) + '%'
              );
            } else {
              return context.dataset.data.reduce((a, b) => a + b, 0);
            }
          },
        },
        legend: {
          labels: {
            generateLabels: function (chart) {
              // Get the default label list
              const original =
                Chart.overrides.pie.plugins.legend.labels.generateLabels;
              const labelsOriginal = original.call(this, chart);

              // Build an array of colors used in the datasets of the chart
              let datasetColors = chart.data.datasets.map(function (e) {
                return e.backgroundColor;
              });
              datasetColors = datasetColors.flat();

              // Modify the color and hide state of each label
              labelsOriginal.forEach((label) => {
                // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                label.datasetIndex = (label.index - (label.index % 2)) / 2;

                // The hidden state must match the dataset's hidden state
                label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                // Change the color to match the dataset
                label.fillStyle = datasetColors[label.index];
              });

              return labelsOriginal;
            },
          },
          onClick: function (mouseEvent, legendItem, legend) {
            // toggle the visibility of the dataset from what it currently is
            legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden =
              legend.chart.isDatasetVisible(legendItem.datasetIndex);
            legend.chart.update();
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const labelIndex = context.datasetIndex * 2 + context.dataIndex;
              if (context.dataIndex === 1) {
                return (
                  context.chart.data.labels[labelIndex] +
                  ': ' +
                  context.dataset.data.reduce((a, b) => a + b, 0)
                );
              } else {
                return (
                  context.chart.data.labels[labelIndex] +
                  ': ' +
                  context.formattedValue
                );
              }
            },
          },
        },
        title: {
          display: true,
          text: 'Users Logged In',
        },
      },
    },
  };

  return (
    <>
      <Doughnut data={data} options={config.options} height="300px" />
    </>
  );
};
export default UserLineChart;
