import React, { createContext, useEffect, useState } from 'react';
import { BACKEND_URL, JWT_KEY, YEAR_KEY } from '../config';
import { deleteCustomerAdmin } from '../services/customerService';
import { getWithExpiry } from '../services/localStorageService';

export const CustomersContext = createContext();

const PPL_TO_DISPLAY = 25;

const CustomersProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [people, setPeople] = useState([]);
  const [filteredPeople, setFilteredPeople] = useState([]);
  const [displayedPeople, setDisplayedPeople] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (people.length > 0) {
      setFilteredPeople(people);
      setDisplayedPeople(people.slice(0, PPL_TO_DISPLAY));
      setIsLoading(false);
      return () => {};
    }

    fetch(`${BACKEND_URL}api/v1/customer/admin/summary?year=${getWithExpiry(YEAR_KEY)}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((people) => {
        people.sort(({ lastName }, { lastName: otherLastName }) =>
          lastName.trim().localeCompare(otherLastName.trim())
        );
        setPeople(people);
        setFilteredPeople(people);
        setDisplayedPeople(people.slice(0, PPL_TO_DISPLAY));
        setIsLoading(false);
      })
      .catch((error) => console.log('No way! ', error));
  }, []);

  const removeCustomer = async (customerIdToRemove) => {
    const { errors } = await deleteCustomerAdmin(customerIdToRemove);

    if (errors) {
      console.log({ errors });
      return;
    }

    setPeople(
      people.filter(({ customerId }) => customerId !== customerIdToRemove)
    );
  };

  const value = {
    isLoading,
    people,
    filteredPeople,
    setFilteredPeople,
    displayedPeople,
    setDisplayedPeople,
    removeCustomer,
  };

  return (
    <CustomersContext.Provider value={{ ...value }}>
      {children}
    </CustomersContext.Provider>
  );
};

export default CustomersProvider;
