import React, {useEffect, useState} from 'react';
import {Button, Table, Icon, Segment, Divider, Header} from "semantic-ui-react";
import "./CustomerOrders.css"
import {BACKEND_URL, JWT_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {toast} from "react-toastify";

const CustomersFormOrder = ({year, invoiceId, customerId, showOrders}) => {
	const [ordersGiving, setOrdersGiving] = useState([]);
	const [customerPekalach, setCustomerPekalach] = useState([]);

	useEffect(() => {
		fetch(`${BACKEND_URL}api/v1/orders/admin/giving/invoice/${invoiceId}?year=${year}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setOrdersGiving(result.map(o => ({...o, id: randomId()})))
			})
			
			.catch((error) => console.log('No way! ', error));
		
		fetch(`${BACKEND_URL}api/v1/item/orders/admin/customer/invoice/${invoiceId}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setCustomerPekalach(result.map(o => ({...o, id: randomId()})))
			})
			
			.catch((error) => console.log('No way! ', error));
		
	}, [])

	const sendReceipt = async () => {
		const response = await fetch(
		  `${BACKEND_URL}api/v1/payment/admin/receipt?customerId=${customerId}&newInvoiceId=${invoiceId}`,
		  {
			method: 'PUT',
			mode: 'cors',
			headers: {
			  'Content-type': 'application/json',
			  Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			}
		  }
		).catch((err) => {
		  console.error(`Error update invoices:`, err);
		  return {errors: err};
		});
		
		if (!response.ok) {
		  const errors = await response.json();
		  console.log('ERROR in response (update invoices): ', errors);
		  return {errors};
		}
		await response.ok;
	  };

	function randomId() {
		return Math.random();
	};	

	const resend = () => {
		sendReceipt().then(()=> 
			toast.dark('Receipt successfully sent!', {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: true
			})
		);
	}
	
	return (
		<div className="orders-item">
			<Segment>
				<Header>Orders</Header>
				<Table className='orders-table'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={10} textAlign={"center"}>Giving To</Table.HeaderCell>
							<Table.HeaderCell textAlign={"center"}>Reciprocated</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{ordersGiving.map((item) => <Table.Row key={item.id}>
							<Table.Cell>{item.name}</Table.Cell>
							<Table.Cell textAlign={"center"}>
							{
								item.reciprocated && <Icon name="checkmark" />
							}
							</Table.Cell>
						</Table.Row>)}
					</Table.Body>
				</Table>

				<Divider section/>

				<Header>Pekalach</Header>
				<Table className='orders-table'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={10} textAlign={"center"}>Name</Table.HeaderCell>
							<Table.HeaderCell textAlign={"center"}>Quantity</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{customerPekalach.map((item) => <Table.Row key={item.id}>
							<Table.Cell>{item.name}</Table.Cell>
							<Table.Cell textAlign={'center'}>
								{item.quantity}
							</Table.Cell>
						</Table.Row>)}
					</Table.Body>
				</Table>

				<Divider section/>

				<div className="flex-container">
					<div class="flex-child">
						{/* <div className={"close-button"}> */}
							<Button onClick={() => resend()}
									basic color='green'>Resend Receipt</Button>
						{/* </div> */}
					<div className="flex-child"></div>
						{/* <div className={"close-button"}> */}
							<Button onClick={() => showOrders(invoiceId)}
									basic color='red'>Close</Button>
						{/* </div> */}
					</div>
				</div>
			</Segment>
		</div>
	);
};

export default CustomersFormOrder;