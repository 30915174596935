import React, { createContext, useState } from 'react';

export const EditCustomerContext = createContext();

const EditCustomerProvider = ({ children }) => {
  const [editInProgress, setEditInProgress] = useState(false);
  const [saveEditInProgress, setSaveEditInProgress] = useState(false);
  const [cancelEditInProgress, setCancelEditInProgress] = useState(false);
  const [errors, setErrors] = useState({});
  const value = {
    editInProgress,
    setEditInProgress,
    saveEditInProgress,
    setSaveEditInProgress,
    cancelEditInProgress,
    setCancelEditInProgress,
    errors,
    setErrors,
  };

  return (
    <EditCustomerContext.Provider value={{ ...value }}>
      {children}
    </EditCustomerContext.Provider>
  );
};

export default EditCustomerProvider;
