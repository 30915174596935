import React, { useContext, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { MinimalCustomersContext } from '../../contexts/MinimalCustomersProvider';
import { NewCustomersContext } from '../../contexts/NewCustomerProvider';
import { mergeCustomersAdmin, updateCustomerAdmin } from '../../services/customerService';
import { getWithExpiry } from '../../services/localStorageService';
import PeopleTable from '../PeopleTable';
import { clean } from '../PersonRow';
import './MergeScreen.css';
import PacmanLoader from 'react-spinners/PacmanLoader';

const prepairDataForDropdown = (users) => users.map(({ customerId, title, firstNames, lastName, addressLine1 }) => ({
  key: customerId,
  text: `${title} ${firstNames} ${lastName} - ${addressLine1}`,
  value: customerId,
  customerId: customerId,
}));

const MergeScreen = () => {
  const [selections, setSelections] = useState(getWithExpiry('ezp.admin.selections') || {});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedNewCustomer, setSelectedNewCustomer] = useState(null);
  const [verifying, setVerifying] = useState(false);

  const { 
    people: newPeople,
    updateNewCustomers,
  } = useContext(NewCustomersContext);
  const { people } = useContext(MinimalCustomersContext);

  const dropdownСhangeHandler = (e, data) => {
    setSelectedCustomer(data.value);
  };

  const newCustomersСhangeHandler = (id) => {
    setSelectedNewCustomer(id);
  };

  const acceptBtnHandler = () => {
    const newCustomer = newPeople.find(customer => customer.customerId === selectedNewCustomer);
    
    (async () => {
      setVerifying(true);
      const { errors } = await updateCustomerAdmin(
        clean({
          customerId: newCustomer.customerId,
          newCustomer: false,
        })
      );
      if (errors) {
        console.log(`Error: ${JSON.stringify(errors)}`);
      }
      
      updateNewCustomers();

      setSelectedNewCustomer(null);
      setVerifying(false);
    })();
  };

  const mergeBtnHandler = () => {
    const newCustomer = newPeople.find(customer => customer.customerId === selectedNewCustomer);

    (async () => {
      setVerifying(true);
      const { errors } = await mergeCustomersAdmin(
        clean({
          addressLine2: newCustomer.addressLine2,
          customerId: newCustomer.customerId,
          defaultGreeting: newCustomer.defaultGreeting,
          email: newCustomer.email,
          firstNames: newCustomer.firstNames,
          fullAddress: newCustomer.fullAddress,
          lastName: newCustomer.lastName,
          phone: newCustomer.phone,
          reciprocation: newCustomer.reciprocation,
          titleId: newCustomer.titleId,
        }), selectedCustomer);
      
      if (errors) {
        console.log(`Error: ${JSON.stringify(errors)}`);
      }

      updateNewCustomers();

      setSelectedNewCustomer(null);
      setSelectedCustomer(null);
      setVerifying(false);
    })();
  };

  return (
    <div className='merge-page-container'>
      <div className='merge-container'>
        {newPeople.length !== 0 ? (
          <PeopleTable
            people={newPeople}
            searchTerm={''}
            selections={selections}
            setSelections={setSelections}
            selectNewCustomer={newCustomersСhangeHandler}
            selectedNewCustomer={selectedNewCustomer}
          />
        ) : (
          <div className="no-data">No data</div>
        )}
        <button type="submit" disabled={!selectedNewCustomer} onClick={() => acceptBtnHandler()} className="email" style={{ display: 'inline'}}>
            {verifying ? (
              <div>
                <PacmanLoader size={10} loading={true} color={'white'} />
              </div>
            ) : (
              <span>Accept New Customer</span>
            )}
        </button>
      </div>

      <div className='merge-container'>
        {people.length !== 0 ? (
          <Dropdown
            placeholder='Search'
            search
            selection
            fluid
            value={selectedCustomer}
            options={prepairDataForDropdown(people)}
            onChange={dropdownСhangeHandler}
            className='customers-dropdown'
          />
        ) : (
          <div className="no-data">No data</div>
        )}
        <button type="submit" disabled={!selectedNewCustomer || !selectedCustomer} onClick={() => mergeBtnHandler()} className="email" style={{ display: 'inline'}}>
            {verifying ? (
              <div>
                <PacmanLoader size={10} loading={true} color={'white'} />
              </div>
            ) : (
              <span>Merge With Customer</span>
            )}
        </button>
      </div>
    </div>
  );
};

export default MergeScreen;
