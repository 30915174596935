import {Bar} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {BACKEND_URL, JWT_KEY, YEAR_KEY} from "../../../config";
import {getWithExpiry} from "../../../services/localStorageService";

const OrdersCardVerticalBar = () => {
	
	const [json, setJson] = useState([]);
	useEffect(() => {
		fetch(`${BACKEND_URL}api/v1/reports/count/consolidated?year=${getWithExpiry(YEAR_KEY)}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip',
				Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setJson(result[0])
			})
			.catch((error) => console.log('No way! ', error));
		
	}, [])
	
	const current_year = json;
	const colors = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a']
	
	const data = {
		labels: ['Baskets to Create', 'Cards To Print'],
		datasets: [
			{
				// label: '',
				data: [current_year.totalBasketsToCreate, current_year.totalCardsToPrint],
				backgroundColor: colors.slice(0, 2),
				minBarLength: 50
			},
		]
	};
	
	const config = {
		type: 'bar',
		data: data,
		options: {
			plugins: {
				title: {
					display: true,
					text: 'Baskets and Cards (Current Year)'
				},
				datalabels: {
					color: 'white',
					font: {
						weight: 'bold'
					},
				},
				legend: {
					display: false
				}
			},
			scales: {
				y: {
					beginAtZero: true,
				},
			},
		},
	};
	
	return (
		<>
			<Bar data={data} options={config.options} height={"300px"}/>
		</>
	)
};
export default OrdersCardVerticalBar
